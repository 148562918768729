import React from 'react'
import { Card, DatePicker, Table, Space } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getPlateCountListAPI } from '../../../../apis/house'
import Dayjs from 'dayjs'
const { RangePicker } = DatePicker
const { Column } = Table

export default function AllCityInfo() {
  let curYear = new Date().getFullYear()
  let curMonth = new Date().getMonth() + 1
  let startYear = curMonth < 3 ? curYear - 1 : curYear
  let startMonth = curMonth < 3 ? curMonth - 2 + 12 : curMonth - 2
  startMonth = startMonth < 10 ? '0' + startMonth : startMonth

  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  const [listQuery, setListQuery] = useState({
    startTime: startYear + '-' + startMonth + '- 01',
    endTime: Dayjs().format('YYYY-MM-DD'),
    districtId: 0,
    type: 0,
    sortField: '',
    sortType: 0
  })
  const [list, setList] = useState([]) //  活跃楼盘列表

  //  获取统计数据
  const fetchData = async () => {
    setListLoading(true)
    const res = await getPlateCountListAPI(listQuery)
    if (res.success === true) {
      if (res.result) {
        res.result.forEach(item => {
          item.id = listQuery.type == 1 ? item.plateId : item.districtId
          item.name = listQuery.type == 1 ? item.plateName : item.districtName
          item.rate = item.soldTotal ? Math.round((item.evidenceRoomNum / item.soldTotal) * 100) / 100 + '：1' : ''
          item.soldTotal = item.soldTotal ? item.soldTotal + '套' : ''
          item.areaTotal = item.areaTotal ? item.areaTotal + '㎡' : ''
          item.evidenceNum = item.evidenceNum ? item.evidenceNum + '个' : ''
          item.evidenceRoomNum = item.evidenceRoomNum ? item.evidenceRoomNum + '套' : ''
          item.evidenceAreaTotal = item.evidenceAreaTotal ? item.evidenceAreaTotal + '㎡' : ''
          item.evidenceStockNum = item.evidenceStockNum ? item.evidenceStockNum + '套' : ''
          item.evidenceSoldCycle = item.evidenceSoldCycle ? item.evidenceSoldCycle + '月' : ''
          item.stockNum = item.stockNum ? item.stockNum + '套' : ''
          item.stockSoldCycle = item.stockSoldCycle ? item.stockSoldCycle + '月' : ''
        })
        setList(res.result)
      }
      setListLoading(false)
    }
  }

  //  日期范围选择器
  const disabledDate = current => {
    //  大于当月不可选
    return current && current > Dayjs().endOf('day')
  }

  const changeType = type => {
    listQuery.type = type
    setListQuery({
      ...listQuery,
      type
    })
    fetchData()
  }
  //  时间段切换
  const changeTime = (dates, dateStrings) => {
    let startTime = dateStrings[0] + '-01'
    let endYear = new Date(dateStrings[1]).getFullYear()
    let endMonth = new Date(dateStrings[1]).getMonth() + 1
    let endTime = ''
    if (endYear == curYear && endMonth == curMonth) {
      endTime = Dayjs().format('YYYY-MM-DD')
    } else {
      endTime = endYear + '-' + (endMonth < 10 ? '0' + endMonth : endMonth) + '-' + Dayjs(dateStrings[1]).daysInMonth()
    }
    listQuery.startTime = startTime
    listQuery.endTime = endTime
    setListQuery({
      ...listQuery,
      startTime,
      endTime
    })
    fetchData()
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <Card className="card">
        <div className="card-title">
          <div className="card-tabs" style={{ borderBottom: 'none', paddingBottom: '0' }}>
            <div className={listQuery.type == 0 ? 'card-tabs-item card-tabs-item-active' : 'card-tabs-item'} onClick={() => changeType(0)}>
              区域
            </div>
            <div className={listQuery.type == 1 ? 'card-tabs-item card-tabs-item-active' : 'card-tabs-item'} onClick={() => changeType(1)}>
              板块
            </div>
          </div>
          <div className="housedetail-signtabs">
            <RangePicker allowClear needConfirm picker="month" value={[Dayjs(listQuery.startTime), Dayjs(listQuery.endTime)]} disabledDate={disabledDate} style={{ width: '300px' }} onChange={changeTime} />
          </div>
        </div>
        <Table dataSource={list} loading={listLoading} pagination={false} rowKey="id" style={{ marginTop: '16px' }}>
          <Column title="区域" dataIndex="name" />
          <Column title="销量" dataIndex="soldTotal" />
          <Column title="面积" dataIndex="areaTotal" />
          <Column title="取证" dataIndex="evidenceNum" />
          <Column title="取证房源" dataIndex="evidenceRoomNum" />
          {/* <Column title="取证面积" dataIndex="evidenceAreaTotal" /> */}
          <Column title="供销比" dataIndex="rate" />
          <Column title="取证库存" dataIndex="evidenceStockNum" />
          <Column title="去化周期" dataIndex="evidenceSoldCycle" />
          <Column title="报规库存" dataIndex="stockNum" />
          <Column title="去化周期" dataIndex="stockSoldCycle" />
          <Column
            title="操作"
            key="action"
            render={(_, record) => (
              <Space size="middle">
                <Link to={'/managecenter/house/detail/' + record.id}>详情</Link>
              </Space>
            )}
          />
        </Table>
      </Card>
    </div>
  )
}
