import React, { useEffect } from 'react'
import './Login.scss'

export default function Login(info) {
  //let loginPopup = props.loginPopup
  useEffect(() => {
    //	实现副作用操作逻辑
    new window.WxLogin({
      self_redirect: false,
      id: 'qrcode',
      appid: 'wxcfaabd88a3ae0c38',
      scope: 'snsapi_login',
      redirect_uri: encodeURIComponent('http://www.wufangtong.com/#/?a=1'),
      state: '321qdsafe2r32dsa',
      style: '',
      href: ''
    })
    return () => {
      //	清除副作用逻辑
    }
  }, [])

  let { onLoginPopup } = info

  return (
    <div className="popup flex-center" onClick={() => onLoginPopup()}>
      <div className="popup-page">
        <div className="title">请使用微信扫码登录</div>
        <div id="qrcode" className="qrcode" />
      </div>
    </div>
  )
}
