import React from 'react'
import { Card, Table, Pagination, Image } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseRoomTypeListAPI } from '../../../../apis/house'
import { useParams } from 'react-router-dom'
const { Column } = Table

export default function RoomTypeList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态

  const location = useParams()
  const id = location.id

  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    houseId: id,
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [list, setList] = useState()

  //  获取楼盘户型列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getHouseRoomTypeListAPI(listQuery)
    if (res.success === true) {
      if (res.result) {
        if (res.result.list) {
          setList(res.result.list)
        }
        setTotal(res.result.total)
      }
      setListLoading(false)
    }
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Card className="card">
      <Table dataSource={list} loading={listLoading} pagination={false}>
        <Column title="户型图" dataIndex="img" key="action" render={(_, record) => <Image width={80} src={record.img + '?x-oss-process=image/resize,w_80,m_lfit'} preview={{ src: record.img }} />} />
        <Column title="户型名称" dataIndex="name" />
        <Column title="房型" dataIndex="roomLayout" />
        <Column title="面积" dataIndex="area" />
        <Column
          title="楼栋分布"
          dataIndex="buildList"
          key="index"
          render={(text, record) => (
            <>
              {record.buildList.map((item, k) => {
                return item.buildShortName + (record.buildList.length - 1 <= k ? '' : '、')
              })}
            </>
          )}
        />
        <Column title="关联房源" dataIndex="roomTotal" />
        <Column title="已售" dataIndex="saleNum" />
        <Column title="未售" dataIndex="availableRoomNum" />
      </Table>
      <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
    </Card>
  )
}
