//  用户相关的所有请求
import { request } from '../utils'
//  登录
export function loginAPI(formData) {
  return request({
    url: '/oauth/WeiXinCallback',
    method: 'GET',
    params: formData
  })
}
//  关注楼盘
export function followHouse(data) {
  return request({
    url: '/data/UserFollowLouPan',
    method: 'POST',
    data
  })
}
