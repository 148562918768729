import React from 'react'
import { Card, DatePicker, Flex, Image, Tabs, Empty } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getSoldChartImgListAPI } from '../../../apis/house'
import Dayjs from 'dayjs'
import './index.scss'

export default function Monitor() {
  const [tabsIndex, setTabsIndex] = useState(0)
  const [tabsList, setTabsList] = useState([])
  const [month, setMonth] = useState(Dayjs().format('YYYY-MM')) //  当前月
  const [listQuery, setListQuery] = useState({
    date: undefined
  })
  const [list, setList] = useState([])

  //  获取统计数据
  const fetchData = async () => {
    const res = await getSoldChartImgListAPI(listQuery)
    if (res.success === true) {
      if (res.result) {
        if (tabsList.length == 0) {
          let month = Dayjs(res.result[0].date).format('YYYY-MM')
          let days = new Date(res.result[0].date).getDate()
          let tabList = []
          for (let i = 0; i < days; i++) {
            tabList.push({
              key: i,
              label: days - i + '日',
              date: month + '-' + (days - i)
            })
          }
          setTabsList(tabList)
          setMonth(month)
        }
        setList(res.result)
      }
    }
  }
  const onChange = (date, dateString) => {
    console.log('%c [ dateString ]-39', 'font-size:13px; background:pink; color:#bf2c9f;', dateString)
    setMonth(dateString)
    let days = Dayjs(dateString).daysInMonth()
    console.log('%c [ days ]-41', 'font-size:13px; background:pink; color:#bf2c9f;', days)
    let tabList = []
    for (let i = 0; i < days; i++) {
      tabList.push({
        key: i,
        label: days - i + '日',
        date: dateString + '-' + (days - i)
      })
    }
    setTabsList(tabList)
    listQuery.date = tabList[0].date
    setListQuery({
      ...listQuery,
      date: tabList[0].date
    })
    setList([])
    setTabsIndex(0)
    fetchData()
  }
  //  tabs切换
  const changeTabs = activeKey => {
    setTabsIndex(activeKey)
    listQuery.date = tabsList[activeKey].date
    setListQuery({
      ...listQuery,
      date: tabsList[activeKey].date
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      <div className="pageTitle">
        <div className="pageTitle-name">销控图集</div>
      </div>
      <div className="monitor-tabs">
        <div className="monitor-tab">
          <Tabs defaultActiveKey="0" activeKey={tabsIndex} items={tabsList} onChange={changeTabs} />
        </div>

        <DatePicker value={Dayjs(month)} onChange={onChange} picker="month" style={{ width: '200px' }} />
      </div>
      <DataPage list={list} />
    </div>
  )
}

function DataPage(props) {
  let list = props.list
  return (
    <Card className="card">
      {list.length > 0 ? (
        <Flex wrap="wrap" gap="middle">
          {list.map((item, index) => {
            return (
              <div className="soldChart-item" key={index}>
                <Image src={item.soldChartImg + '?x-oss-process=image/resize,w_160,m_fill'} className="img" preview={{ src: item.soldChartImg }} />
                <div className="name ellipsis">
                  <Link to="" className="color-576b95">
                    {item.name}
                  </Link>
                </div>
                <div className="price">
                  参考价<span className="red">{item.salePrice}元/㎡</span>
                </div>
                <div className="price">
                  30天网签<span className="red">{item.soldNum}</span>套
                </div>
              </div>
            )
          })}
        </Flex>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  )
}
