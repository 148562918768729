import React from 'react'
import { Card, Input, Button, Table, Space, Pagination, Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDevelopersListAPI, getAllBrandListAPI } from '../../../apis/house'
import './index.scss'
const { Column } = Table
export default function DevelopersList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    keywords: '',
    brandId: undefined,
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [brandList, setBrandList] = useState([]) // 品牌列表

  //  分页参数变更
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }
  //  搜索
  const searchFinish = values => {
    console.log('%c [ values ]-78', 'font-size:13px; background:pink; color:#bf2c9f;', values)
    listQuery.keywords = values.keywords
    listQuery.brandId = values.brandId
    listQuery.pageInt = 1
    setListQuery({
      ...listQuery
    })
    fetchData()
  }

  //  获取楼盘列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getDevelopersListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach((item, index) => {
          item.key = index
        })
      }
      setList(res.result.list)
      setTotal(res.result.totalCount)
      setListLoading(false)
    }
  }

  //  获取全部品牌列表
  const getAllBrandList = async () => {
    const res = await getAllBrandListAPI()
    if (res.success === true) {
      if (res.result) {
        let brandList = []
        res.result.forEach(item => {
          brandList.push({
            value: item.id,
            label: item.name
          })
        })
        setBrandList(brandList)
      }
    }
  }
  const selectChange = value => {
    console.log(`selected ${value}`)
  }
  const selectSearch = value => {
    console.log('search:', value)
  }

  //  获取文章列表
  const [list, setList] = useState()
  useEffect(() => {
    fetchData()
    getAllBrandList()
  }, [])

  return (
    <div>
      <div className="pageTitle">开发商</div>
      <Card className="card">
        <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm">
          <Form.Item name="keywords">
            <Input allowClear placeholder="开发商名称" style={{ width: '300px' }} />
          </Form.Item>
          <Form.Item name="brandId">
            <Select allowClear showSearch placeholder="所属品牌" optionFilterProp="label" onChange={selectChange} onSearch={selectSearch} options={brandList} style={{ width: '300px', marginLeft: '10px' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
              搜索
            </Button>
          </Form.Item>
        </Form>
        <Table dataSource={list} loading={listLoading} pagination={false}>
          <Column title="开发商" dataIndex="name" />
          <Column title="归属品牌" dataIndex="brandName" />
          <Column title="关联项目地块" dataIndex="projectNum" />
          <Column title="关联楼盘" dataIndex="houseNum" />
          <Column
            title="操作"
            key="action"
            render={(_, record) => (
              <Space size="middle">
                <Link to={'/managecenter/developers/detail/' + record.id}>详情</Link>
              </Space>
            )}
          />
        </Table>
        <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
      </Card>
    </div>
  )
}
