import React from 'react'
import { Tabs } from 'antd'
import ExponentRank from './components/exponentRank'
import SoldRank from './components/soldRank'
import SoldPriceRank from './components/soldPriceRank'
import './index.scss'

const tabsList = [
  {
    key: '0',
    label: '武房指数榜',
    children: <ExponentRank />
  },
  {
    key: '1',
    label: '新房销量榜',
    children: <SoldRank />
  },
  {
    key: '2',
    label: '新房销售额榜',
    children: <SoldPriceRank />
  }
]

export default function Index() {
  return (
    <div>
      <div className="pageTitle">
        <div className="pageTitle-name">武房通榜单</div>
      </div>
      <div className="tabs">
        <Tabs defaultActiveKey="0" items={tabsList} />
      </div>
    </div>
  )
}
