import React from 'react'
import { Card, Col, Row, Tabs, Table, Form, Input, Select, DatePicker, Space, Button, Pagination } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseSignListAPI, getHouseEvidenceListAPI, getHouseSoldRoomListAPI } from '../../../../apis/house'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Dayjs from 'dayjs'
import styles from '../index.module.scss'
const { Column } = Table
const { RangePicker } = DatePicker
const tabList = [
  {
    key: '0',
    label: '楼盘网签',
    children: <HouseSignList />
  },
  {
    key: '1',
    label: '房源监测',
    children: <HouseRoomList />
  }
]
export default function SignMonitor() {
  const navigate = useNavigate()

  const [tabsKey, setTabsKey] = useState()
  let searchParams = useLocation()
  console.log('%c [ searchParams ]-29', 'font-size:13px; background:pink; color:#bf2c9f;', searchParams)

  //  tab切换
  const changeTabs = key => {
    let path = searchParams.pathname + '?tab=' + key
    navigate(path)
    setTabsKey(key)
  }

  useEffect(() => {
    if (searchParams.search) {
      let params = searchParams.search.replace('?', '')
      params = params.split('&')
      if (params) {
        params.forEach(item => {
          let tmp = item.split('=')
          if (tmp && tmp[0] === 'tab') {
            console.log('%c [ tmp ]-36', 'font-size:13px; background:pink; color:#bf2c9f;', tmp)
            setTabsKey(tmp[1])
          }
        })
      }
    }
  }, [tabsKey])
  return (
    <Card className="card soldChart">
      <Tabs activeKey={tabsKey} items={tabList} onTabClick={changeTabs} />
    </Card>
  )
}

function HouseSignList() {
  const location = useParams()
  const id = location.id

  let curYear = new Date().getFullYear()
  let curMonth = new Date().getMonth() + 1
  let curDay = new Date().getDate()
  let lastTime = new Date(curYear - 1 + '-' + curMonth + '-' + curDay).getTime() + 86400000
  if (curMonth === 2 && curDay === 29) {
    lastTime = new Date(curYear - 1 + '-' + curMonth + '-' + curDay - 1).getTime() + 86400000
  }
  let lastYear = new Date(lastTime).getFullYear()
  let lastMonth = new Date(lastTime).getMonth() + 1
  lastMonth = lastMonth > 9 ? lastMonth : '0' + lastMonth
  let lastDay = new Date(lastTime).getDate()
  lastDay = lastDay > 9 ? lastDay : '0' + lastDay

  const [listQuery, setListQuery] = useState({
    id: id,
    startTime: lastYear + '-' + lastMonth + '-' + lastDay,
    endTime: Dayjs().format('YYYY-MM-DD')
  })

  const [dayListLoading, setDayListLoading] = useState(false) //  表格加载状态
  const [dayList, setDayList] = useState([]) //  日网签列表
  const [weekListLoading, setWeekListLoading] = useState(false) //  表格加载状态
  const [weekList, setWeekList] = useState([]) //  周网签列表
  const [monthListLoading, setMonthListLoading] = useState(false) //  表格加载状态
  const [monthList, setMonthList] = useState([]) //  月网签列表

  //  网签列表
  const getHouseSignList = async () => {
    setDayListLoading(true)
    setWeekListLoading(true)
    setMonthListLoading(true)
    const res = await getHouseSignListAPI(listQuery)
    if (res.success === true) {
      setDayList(res.result)
      setDayListLoading(false)
      getWeekDataList(JSON.parse(JSON.stringify(res.result)))
      getMonthDataList(JSON.parse(JSON.stringify(res.result)))
    }
  }

  //  周列表数据处理
  const getWeekDataList = list => {
    let weekList = []
    if (list) {
      let newTime = new Date(list[0].time).getTime()
      list.forEach(item => {
        item.soldNum = item.soldNum || 0

        let week = new Date(item.time).getDay()
        week = week == 0 ? 7 : week
        item.startTime = new Date(item.time).getTime() - (week - 1) * 86400000
        item.endTime = new Date(item.time).getTime() + (7 - week) * 86400000
        if (item.endTime > newTime) {
          item.endTime = newTime
        }
      })

      let weekTmpList = list.reduce((acc, current) => {
        let existing = acc.find(item => item.startTime === current.startTime)
        if (existing) {
          existing.soldNum += current.soldNum
        } else {
          acc.push(current)
        }
        return acc
      }, [])
      if (weekTmpList) {
        weekTmpList.forEach(item => {
          weekList.push({
            time: Dayjs(item.startTime).format('YYYY-MM-DD') + '至' + Dayjs(item.endTime).format('YYYY-MM-DD'),
            soldNum: item.soldNum
          })
        })
      }
    }
    setWeekList(weekList)
    setWeekListLoading(false)
  }

  //  月列表数据处理
  const getMonthDataList = list => {
    let monthList = []
    if (list) {
      list.forEach(item => {
        item.soldNum = item.soldNum || 0

        let year = new Date(item.time).getFullYear()
        let month = new Date(item.time).getMonth() + 1
        item.startTime = new Date(year + '/' + month + '/1').getTime()
      })

      let monthTmpList = list.reduce((acc, current) => {
        let existing = acc.find(item => item.startTime === current.startTime)
        if (existing) {
          existing.soldNum += current.soldNum
        } else {
          acc.push(current)
        }
        return acc
      }, [])
      if (monthTmpList) {
        monthTmpList.forEach(item => {
          monthList.push({
            time: Dayjs(item.startTime).format('YYYY-MM月'),
            soldNum: item.soldNum
          })
        })
      }
    }
    setMonthList(monthList)
    setMonthListLoading(false)
  }

  //  搜索日期改变
  const changeTime = (dates, dateStrings) => {
    console.log('%c [ dateStrings ]-151', 'font-size:13px; background:pink; color:#bf2c9f;', dateStrings)
    listQuery.startTime = dateStrings[0]
    listQuery.endTime = dateStrings[1]
    setListQuery({
      ...listQuery,
      startTime: dateStrings[0],
      endTime: dateStrings[1]
    })
    getHouseSignList()
  }

  useEffect(() => {
    getHouseSignList()
  }, [])
  return (
    <div>
      <div className={styles['countCondition']} style={{ marginTop: '24px' }}>
        <RangePicker
          allowClear
          needConfirm={true}
          picker="date"
          defaultValue={[Dayjs(listQuery.startTime, 'YYYY-MM-DD'), Dayjs(listQuery.endTime, 'YYYY-MM-DD')]}
          placeholder={['最新取证开始时间', '最新取证截止时间']}
          style={{ width: '300px', marginLeft: '10px' }}
          onChange={changeTime}
        />
        <div className={styles['download']}>
          <div className="iconfont icon-download" />
          <div>导出</div>
        </div>
      </div>
      <Row gutter={16} style={{ marginTop: '24px' }}>
        <Col span={8}>
          <Table dataSource={dayList} loading={dayListLoading} pagination={false}>
            <Column title="时间" dataIndex="time" />
            <Column title="日-网签套数" dataIndex="soldNum" render={(_, record) => record.soldNum || 0} />
          </Table>
        </Col>
        <Col span={8}>
          <Table dataSource={weekList} loading={weekListLoading} pagination={false}>
            <Column title="时间" dataIndex="time" />
            <Column title="周-网签套数" dataIndex="soldNum" />
          </Table>
        </Col>
        <Col span={8}>
          <Table dataSource={monthList} loading={monthListLoading} pagination={false}>
            <Column title="时间" dataIndex="time" />
            <Column title="月-网签套数" dataIndex="soldNum" />
          </Table>
        </Col>
      </Row>
    </div>
  )
}

function HouseRoomList() {
  const [listLoading] = useState(false) //  表格加载状态
  const location = useParams()
  const id = location.id

  const [listQuery, setListQuery] = useState({
    pageInt: 1,
    pageSize: 10,
    id: id, //	楼盘编号
    evidenceId: null, //	预售证编号
    signStartTime: '', //  网签起始日期
    signEndTime: '', //  网签截止日期
    totalPriceStart: null, //	起始总价
    totalPriceEnd: null, //	截止总价
    priceStart: null, //	起始单价
    priceEnd: null //	截止单价
  })
  const [roomTotal, setRoomTotal] = useState(0) //  列表总数
  const [evidenceList, setEvidenceList] = useState([])
  const [roomList, setRoomList] = useState(0) //  列表总数

  //  获取预售证列表
  const getEvidenceList = async () => {
    const res = await getHouseEvidenceListAPI({ houseId: id })
    if (res.success === true) {
      if (res.result.list) {
        let tmplist = []
        res.result.list.forEach(item => {
          tmplist.push({
            title: item.name,
            value: item.id,
            label: item.name
          })
        })
        setEvidenceList(tmplist)
      }
    }
  }
  //  获取已售房源列表
  const fetchData = async () => {
    listQuery.evidenceId = Number(listQuery.evidenceId)
    listQuery.totalPriceStart = Number(listQuery.totalPriceStart)
    listQuery.totalPriceEnd = Number(listQuery.totalPriceEnd)
    listQuery.priceStart = Number(listQuery.priceStart)
    listQuery.priceEnd = Number(listQuery.priceEnd)
    const res = await getHouseSoldRoomListAPI(listQuery)
    if (res.success === true) {
      setRoomTotal(res.result.total)
      if (res.result.list) {
        res.result.list.forEach(item => {
          item.soldTime = Dayjs(item.soldTime).format('YYYY-MM-DD')
          item.saleStatus2 = item.saleStatus == 1 ? '已售' : item.saleStatus == 2 ? '未售' : ''
          item.unitName = item.unitName == '/' ? '' : item.unitName + '单元'
        })
      }
      setRoomList(res.result.list)
    }
  }
  // 搜索
  const searchFinish = values => {
    console.log('%c [ values ]-152', 'font-size:13px; background:pink; color:#bf2c9f;', values)
    listQuery.evidenceId = values.evidenceId
    let signStartTime = ''
    let signEndTime = ''
    if (values.signEndTime) {
      signStartTime = Dayjs(values.signEndTime[0]).format('YYYY-MM-DD')
      signEndTime = Dayjs(values.signEndTime[1]).format('YYYY-MM-DD')
    }
    listQuery.signStartTime = signStartTime
    listQuery.signEndTime = signEndTime

    let totalPriceEnd = Number(values.totalPriceEnd)
    let totalPriceStart = Number(values.totalPriceStart)
    if (totalPriceEnd < totalPriceStart) {
      totalPriceStart = Number(values.totalPriceStart)
      totalPriceEnd = Number(values.totalPriceEnd)
    }
    listQuery.totalPriceStart = totalPriceStart
    listQuery.totalPriceEnd = totalPriceEnd

    let priceEnd = Number(values.priceEnd)
    let priceStart = Number(values.priceStart)
    if (priceEnd < priceStart) {
      priceStart = Number(values.priceStart)
      priceEnd = Number(values.priceEnd)
    }
    listQuery.priceStart = priceStart
    listQuery.priceEnd = priceEnd
    listQuery.pageInt = 1
    setListQuery({
      ...listQuery
    })
    fetchData()
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }
  useEffect(() => {
    getEvidenceList()
    fetchData()
  }, [])
  return (
    <div style={{ marginTop: '24px' }}>
      <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm">
        <Form.Item name="evidenceId">
          <Select allowClear options={evidenceList} placeholder="请选择预售证" style={{ width: '250px' }} />
        </Form.Item>
        <Form.Item name="signEndTime">
          <RangePicker allowClear needConfirm picker="date" placeholder={['网签-开始时间', '网签-结束时间']} style={{ width: '300px', marginLeft: '10px' }} />
        </Form.Item>
        <Space.Compact size="middle" style={{ width: '300px', marginLeft: '10px' }}>
          <Form.Item name="totalPriceStart">
            <Input addonAfter="元" placeholder="起始总价" />
          </Form.Item>
          <Form.Item name="totalPriceEnd">
            <Input addonAfter="元" placeholder="截止总价" />
          </Form.Item>
        </Space.Compact>
        <Space.Compact size="middle" style={{ width: '300px', marginLeft: '10px' }}>
          <Form.Item name="priceStart">
            <Input addonAfter="元" placeholder="起始均价" />
          </Form.Item>
          <Form.Item name="priceEnd">
            <Input addonAfter="元" placeholder="截止均价" />
          </Form.Item>
        </Space.Compact>
        <Form.Item>
          <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
            搜索
          </Button>
        </Form.Item>
      </Form>
      <Table dataSource={roomList} loading={listLoading} pagination={false}>
        <Column title="预售证" dataIndex="evidenceName" />
        <Column title="楼栋" dataIndex="buildName" />
        <Column title="单元" dataIndex="unitName" />
        <Column title="楼层" dataIndex="floor" />
        <Column title="房号" dataIndex="roomName" />
        <Column title="面积" dataIndex="area" />
        <Column title="单价" dataIndex="price" />
        <Column title="总价" dataIndex="totalPrice" />
        <Column title="交付标准" dataIndex="delivery" />
        <Column title="状态" dataIndex="saleStatus2" />
        <Column title="状态更新时间" dataIndex="soldTime" />
      </Table>
      <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={roomTotal} showSizeChanger showQuickJumper onChange={onChange} />
    </div>
  )
}
