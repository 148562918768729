import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Index from '../view/Index'
import ManageCenter from '../view/ManageCenter' // 数据中心
import NotFound from '../view/NotFound' // 404页面
import HouseList from '../view/ManageCenter/House/index' //  楼盘列表
import HouseDetail from '../view/ManageCenter/House/detail' // 楼盘详情
import EvidenceList from '../view/ManageCenter/House/evidenceList' //  楼盘预售证列表
import ProjectList from '../view/ManageCenter/House/projectList' //  楼盘项目列表
import HouseTree from '../view/ManageCenter/House/houseTree' //  竞品雷达
import BrandList from '../view/ManageCenter/House/brandList' //  品牌列表
import BrandMap from '../view/ManageCenter/House/brandMap' //  品牌地图
import DevelopersList from '../view/ManageCenter/House/developersList' //  开发商列表
import DevelopersDetail from '../view/ManageCenter/House/developersDetail' //  开发商详情
import SupplyMarket from '../view/ManageCenter/SupplyMarket/index' //  供需存
import District from '../view/ManageCenter/SupplyMarket/district' // 区域供需存
import Monitor from '../view/ManageCenter/SupplyMarket/monitor' // 实时网签
import SoldChartList from '../view/ManageCenter/SupplyMarket/soldChartList' // 销控图
import StockMap from '../view/ManageCenter/SupplyMarket/stockMap' // 库存地图
import Rank from '../view/ManageCenter/Rank/index'
import { AuthRoute } from '../components/AuthRoute'

export default function router() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route
          path="/managecenter"
          element={
            <AuthRoute>
              <ManageCenter />
            </AuthRoute>
          }
        >
          <Route path="/managecenter/house/:name/:id" element={<HouseDetail />} />
          <Route path="/managecenter/house" element={<HouseList />} />
          <Route path="/managecenter/evidence" element={<EvidenceList />} />
          <Route path="/managecenter/project" element={<ProjectList />} />
          <Route path="/managecenter/housetree" element={<HouseTree />} />
          <Route path="/managecenter/supplymarket" element={<SupplyMarket />} />
          <Route path="/managecenter/supplymarket/district" element={<District />} />
          <Route path="/managecenter/supplymarket/monitor" element={<Monitor />} />
          <Route path="/managecenter/supplymarket/soldchart" element={<SoldChartList />} />
          <Route path="/managecenter/supplymarket/stockmap" element={<StockMap />} />
          <Route path="/managecenter/rank" element={<Rank />} />
          <Route path="/managecenter/brand" element={<BrandList />} />
          <Route path="/managecenter/brandmap/:id" element={<BrandMap />} />
          <Route path="/managecenter/developers" element={<DevelopersList />} />
          <Route path="/managecenter/developers/detail/:id" element={<DevelopersDetail />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </HashRouter>
  )
}
