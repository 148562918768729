import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './index.scss'
import Login from '../../components/login/Login'
import { useDispatch } from 'react-redux'
import { fetchLogin } from '../../store/modules/user'
import { getUserInfo as _getUserInfo } from '../../utils'

export default function Index() {
  const [loginPopup, setLoginPopup] = useState(false)
  const [userInfo, setUserInfo] = useState(_getUserInfo() || {})
  console.log('%c [ userInfo ]-13', 'font-size:13px; background:pink; color:#bf2c9f;', userInfo)

  const location = useLocation()
  console.log('%c [ location ]-15', 'font-size:13px; background:pink; color:#bf2c9f;', location)
  const params = new URLSearchParams(location.search)

  const code = params.get('code')
  console.log('%c [ code ]-17', 'font-size:13px; background:pink; color:#bf2c9f;', code)
  const state = params.get('state')
  console.log('%c [ state ]-19', 'font-size:13px; background:pink; color:#bf2c9f;', state)

  const dispatch = useDispatch()
  const getLoginQrcode = () => {
    console.log('%c [ loginPopup ]-26', 'font-size:13px; background:pink; color:#bf2c9f;', loginPopup)
    setLoginPopup(!loginPopup)
  }
  if (!userInfo.token) {
    const getUserInfo = async value => {
      if (value.code) {
        await dispatch(fetchLogin(value))
        setUserInfo(_getUserInfo())
        // userInfo = setUserInfo(_getUserInfo())
        //setLoginPopup(false)
        window.location.reload()
      }
    }
    if (code) {
      getUserInfo({ code, state })
      //setLoginPopup(false)
    }
  }

  return (
    <div>
      <div className="top">
        <div className="top-center h-full flex-between">
          <img src="/images/logo.png" className="logo" />
          <div className="navlist flex-center h-full">
            <div className="nav-item cur">首页</div>
            <div className="nav-item">关于我们</div>
            {userInfo.token ? (
              <div className="nav-item">
                <Link className="manageCenter" to="/managecenter/house">
                  数据中心
                </Link>
                <img src={userInfo.avatar} className="avatar" />
              </div>
            ) : (
              <div className="nav-item" onClick={getLoginQrcode}>
                登录
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="banner">
        <img src="/images/banner.png" className="banner-bg" />
        <div className="banner-center">
          <div className="banner-content">
            <div className="banner-tit">武房通</div>
            <div className="banner-tit">AI房产数据专家</div>
            <div className="banner-desc">
              <div>立足武汉，面向全国主要一二线城市，</div>
              <div>基于AI技术，专注房产数据挖掘。</div>
            </div>
            <div className="banner-btn">免费体验</div>
          </div>
        </div>
      </div>
      <div className="dealcase">
        <div className="dealcase-center">
          <div className="dealcase-tit">解决方案</div>
          <div className="dealcase-list">
            <div className="dealcase-item">
              <div className="dealcase-item-tit">开发商/策划</div>
              <div className="dealcase-item-content">竞品监测、潜在竞品挖掘、区域供销存、区域客户研究、区域畅销产品、精准用户线索、基础数据下载</div>
            </div>
            <div className="dealcase-item">
              <div className="dealcase-item-tit">中介/置业顾问</div>
              <div className="dealcase-item-content">竞品监测、楼盘销控、精准用户线索，获客工具（房产云笔记）</div>
            </div>
            <div className="dealcase-item">
              <div className="dealcase-item-tit">房产自媒体</div>
              <div className="dealcase-item-content">全市房产数据、区域房产数据、榜单、销控图、热门楼盘发掘、一房一价数据包</div>
            </div>
            <div className="dealcase-item">
              <div className="dealcase-item-tit">金融机构</div>
              <div className="dealcase-item-content">区域客户研究、市场研究基础数据、热门/热销楼盘发掘</div>
            </div>
          </div>
        </div>
      </div>
      <div className="function">
        <div className="function-center">
          <div className="function-tit">功能展示</div>
          <div className="function-smalltit">竞品雷达</div>
          <div className="function-desc">基于用户大数据，发掘楼盘潜在竞品</div>
          <img src="/images/1.jpg" className="function-img" />
          <div className="function-list">
            <div className="function-item">
              <div className="function-item-li">
                <div className="function-item-tit">竞品监测</div>
                <div>竞品销售监测</div>
                <div>潜在竞品挖掘</div>
                <div>竞品雷达</div>
              </div>
              <div className="function-item-li">
                <div className="function-item-tit">用户线索</div>
                <div>AI大数据线索引擎</div>
                <div>精准</div>
                <div>更易转化</div>
              </div>
            </div>
            <div className="function-item">
              <div className="function-item-li">
                <div className="function-item-tit">供销存</div>
                <div>全市供销存</div>
                <div>15区60+板块供销存</div>
                <div>库存地图</div>
                <div>房源量/价/面积分布</div>
                <div>每日实时网签监测</div>
                <div>全市销控图</div>
              </div>
              <div className="function-item-li">
                <div className="function-item-tit">数据大屏</div>
                <div>可定制数据大屏</div>
              </div>
            </div>
            <div className="function-item">
              <div className="function-item-li">
                <div className="function-item-tit">武房榜</div>
                <div>武房指数榜</div>
                <div>销量榜、销售额榜</div>
                <div>热度榜</div>
                <div>自定义榜单</div>
              </div>
              <div className="function-item-li">
                <div className="function-item-tit">房产云笔记</div>
                <div>手机、PC同步</div>
                <div>可私有可分享可获客</div>
              </div>
            </div>
            <div className="function-item">
              <div className="function-item-li">
                <div className="function-item-tit">房产数据包</div>
                <div>楼盘数据包</div>
                <div>预售证数据包</div>
                <div>项目地块数据包</div>
                <div>一房一价数据包</div>
                <div>开发商名录</div>
              </div>
              <div className="function-item-li">
                <div className="function-item-tit">购房者研究</div>
                <div>区域购房者属性</div>
                <div>购房者偏好研究</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer">
          Copyright © 2023 武房通 鄂ICP备19026447号
        </a>
      </div>
      {loginPopup ? <Login loginPopup={loginPopup} onLoginPopup={getLoginQrcode} /> : ''}
    </div>
  )
}
