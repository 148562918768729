//  和用户相关的状态管理

import { createSlice } from '@reduxjs/toolkit'
import { setToken as _setToken, getToken, setUserInfo as _setUserInfo, getUserInfo } from '../../utils'
import { loginAPI } from '../../apis/user'

const userStore = createSlice({
  name: 'user',
  //  数据状态
  initialState: {
    token: getToken() || '',
    userInfo: getUserInfo() || {}
  },
  //  同步修改方法
  reducers: {
    setToken(state, action) {
      state.token = action.payload
      _setToken(action.payload)
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload
      _setUserInfo(action.payload)
    }
  }
})

//  解构出actionCreater
const { setToken, setUserInfo } = userStore.actions

//  获取reducer函数
const userReducer = userStore.reducer

//  异步请求方法 完成登录获取token
const fetchLogin = loginForm => {
  return async dispatch => {
    //  1. 发送异步请求
    const res = await loginAPI(loginForm)
    if (res.success === true) {
      //  2. 提交同步action进行token的存入
      dispatch(setToken(res.result.token))
      dispatch(setUserInfo(res.result))
    }
  }
}

export { fetchLogin, setToken, setUserInfo }

export default userReducer
