import React, { useCallback } from 'react'
import { Card, Col, Flex, Row, Image, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getHouseSoldChartListAPI, getHouseDetailAPI, getHouseSignListAPI, getHousePriceAccountAPI } from '../../../../apis/house'
import Dayjs from 'dayjs'
import * as echarts from 'echarts'

const tabList = ['自然日', '自然周', '自然月']
var myChart
var myChartLine

export default function HouseInfo({ changeLinkTabs }) {
  const location = useParams()
  const id = location.id

  const [houseInfo, setHouseInfo] = useState({}) //  楼盘详情
  const [projectList, setProjectList] = useState([])
  const [tabIndex, setTabIndex] = useState(1)

  let curWeek = new Date().getDay()
  curWeek = curWeek ? curWeek : 7
  let startTime = new Date().getTime() - curWeek * 86400000 - 29 * 7 * 86400000
  let startYear = new Date(startTime).getFullYear()
  let startMonth = new Date(startTime).getMonth() + 1
  startMonth = startMonth < 10 ? '0' + startMonth : startMonth
  let startDay = new Date(startTime).getDate()
  startDay = startDay < 10 ? '0' + startDay : startDay

  const [signListQuery, setSignListQuery] = useState({
    id: id,
    startTime: startYear + '-' + startMonth + '-' + startDay,
    endTime: Dayjs().format('YYYY-MM-DD')
  })
  const [, setChartsData] = useState({})

  //  楼盘详情
  const getDetail = async () => {
    const res = await getHouseDetailAPI({ id })
    if (res.success === true) {
      if (res.result) {
        res.result.roomTotalRankRate = (100 - (res.result.roomTotalRank / res.result.roomTotalRankTotal) * 100).toFixed(0)
      }
      setHouseInfo(res.result)
    }
  }

  //  价格走势
  const getPriceAccount = async () => {
    const res = await getHousePriceAccountAPI({ id })
    console.log('%c [ res ]-50', 'font-size:13px; background:pink; color:#bf2c9f;', res)
    if (res.success === true) {
      if (res.result) {
        if (res.result.priceData) {
          const uniqueArray = Array.from(
            res.result.priceData
              .reduce((map, item) => {
                map.set(Dayjs(item.date).format('YYYY-MM-DD'), item)
                return map
              }, new Map())
              .values()
          )
          let titleList = []
          let priceList = []
          let averagePriceList = []
          uniqueArray.forEach(item => {
            if (res.result.evidencePrice) {
              let difference = 0
              let averagePriceIndex = 0
              res.result.evidencePrice.forEach((ele, index) => {
                if (Dayjs(item.date).unix() == Dayjs(ele.date).unix()) {
                  item.averagePrice = ele.averagePrice
                } else {
                  let time = Dayjs(item.date).unix() - Dayjs(ele.date).unix()
                  if (time > 0) {
                    if (difference) {
                      if (difference > time) {
                        averagePriceIndex = index
                        difference = time
                      }
                    } else {
                      averagePriceIndex = index
                      difference = time
                    }
                  }
                }
              })
              item.averagePrice = Math.round(item.averagePrice ? item.averagePrice : res.result.evidencePrice[averagePriceIndex].averagePrice)
            }
            titleList.push(Dayjs(item.date).format('MM月DD日'))
            priceList.push(item.price)
            averagePriceList.push(item.averagePrice || 0)
          })
          var option
          option = {
            color: ['#07C160', '#3BA0FF', '#36CBCB', '#FAD337', '#F2637B', '#975FE4'],
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: titleList
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '备案均价',
                data: averagePriceList,
                type: 'line',
                smooth: true,
                showSymbol: false
              },
              {
                name: '参考价',
                data: priceList,
                type: 'line',
                smooth: true,
                showSymbol: false
              }
            ]
          }

          option && myChartLine.setOption(option)
        }
      }
    }
  }

  //  销控图列表
  const getProjectList = async () => {
    const res = await getHouseSoldChartListAPI({ id })
    console.log('%c [ res ]-26', 'font-size:13px; background:pink; color:#bf2c9f;', res)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list = res.result.list.splice(0, 2)
        res.result.list.forEach(item => {
          item.soldChartImg2 = item.soldChartImg.splice(0, 6)
        })
        setProjectList(res.result.list)
      }
    }
  }
  //  获取网签数据
  const getHouseSignList = async (listQuery, index) => {
    console.log('%c [ listQuery ]-65', 'font-size:13px; background:pink; color:#bf2c9f;', listQuery)
    const res = await getHouseSignListAPI(listQuery)
    if (res.success === true) {
      if (res.result) {
        let titleList = []
        let dataList = []
        //  周
        if (index == 1) {
          let newTime = new Date(res.result[0].time).getTime()
          res.result.forEach(item => {
            item.soldNum = item.soldNum || 0

            let week = new Date(item.time).getDay()
            week = week == 0 ? 7 : week
            item.startTime = new Date(item.time).getTime() - (week - 1) * 86400000
            item.endTime = new Date(item.time).getTime() + (7 - week) * 86400000
            if (item.endTime > newTime) {
              item.endTime = newTime
            }
          })

          let weekTmpList = res.result.reduce((acc, current) => {
            let existing = acc.find(item => item.startTime === current.startTime)
            if (existing) {
              existing.soldNum += current.soldNum
            } else {
              acc.push(current)
            }
            return acc
          }, [])
          weekTmpList = weekTmpList.reverse()

          if (weekTmpList) {
            weekTmpList.forEach(item => {
              titleList.push(Dayjs(item.startTime).format('YYYY-MM-DD') + '至' + Dayjs(item.endTime).format('YYYY-MM-DD'))
              dataList.push(item.soldNum)
            })
          }
        } else if (index == 2) {
          res.result.forEach(item => {
            item.soldNum = item.soldNum || 0

            let year = new Date(item.time).getFullYear()
            let month = new Date(item.time).getMonth() + 1
            item.startTime = new Date(year + '/' + month + '/1').getTime()
          })

          let monthTmpList = res.result.reduce((acc, current) => {
            let existing = acc.find(item => item.startTime === current.startTime)
            if (existing) {
              existing.soldNum += current.soldNum
            } else {
              acc.push(current)
            }
            return acc
          }, [])

          monthTmpList = monthTmpList.reverse()
          if (monthTmpList) {
            monthTmpList.forEach(item => {
              titleList.push(Dayjs(item.startTime).format('YYYY-MM月'))
              dataList.push(item.soldNum)
            })
          }
        } else {
          res.result = res.result.reverse()
          res.result.forEach(item => {
            titleList.push(Dayjs(item.time).format('DD日'))
            dataList.push(item.soldNum)
          })
        }
        setChartsData({
          titleList,
          dataList
        })
        var option

        option = {
          color: ['#07C160'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            data: titleList
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '客流量',
              data: dataList,
              type: 'bar',
              barWidth: 8
            }
          ]
        }

        option && myChart.setOption(option)
      }
    }
  }

  //  切换网签柱形图
  const changeTabs = index => {
    console.log('%c [ index ]-176', 'font-size:13px; background:pink; color:#bf2c9f;', index)
    if (index == tabIndex) {
      return false
    }
    setTabIndex(index)
    let signListQuery = {
      id: id,
      endTime: Dayjs().format('YYYY-MM-DD')
    }
    if (index == 1) {
      let curWeek = new Date().getDay()
      curWeek = curWeek ? curWeek : 7
      let startTime = new Date().getTime() - curWeek * 86400000 - 29 * 7 * 86400000
      let startYear = new Date(startTime).getFullYear()
      let startMonth = new Date(startTime).getMonth() + 1
      startMonth = startMonth < 10 ? '0' + startMonth : startMonth
      let startDay = new Date(startTime).getDate()
      startDay = startDay < 10 ? '0' + startDay : startDay

      signListQuery.startTime = startYear + '-' + startMonth + '-' + startDay
    } else if (index == 2) {
      let curMonth = new Date().getMonth() + 1
      let curYear = new Date().getFullYear()

      let startYear = curYear - Math.ceil((13 - curMonth) / 12)
      let startMonth = 12 - ((13 - curMonth) % 12) + 1
      startMonth = startMonth < 10 ? '0' + startMonth : startMonth

      signListQuery.startTime = startYear + '-' + startMonth + '-01'
    } else {
      let startTime = new Date().getTime() - 30 * 86400000
      signListQuery.startTime = Dayjs(startTime).format('YYYY-MM-DD')
    }
    setSignListQuery(signListQuery)
    getHouseSignList(signListQuery, index)
  }

  useEffect(() => {
    var chartDom = document.getElementById('echarts-bar')
    myChart = echarts.init(chartDom)
    var chartLineDom = document.getElementById('echarts-line')
    myChartLine = echarts.init(chartLineDom)
    getDetail()
    getProjectList()
    getPriceAccount()
    getHouseSignList(signListQuery, 1)
  }, [])

  const newLocal = '100%'
  return (
    <div>
      <Card
        className="card"
        title="核心数据"
        extra={
          <div className="housedetail-infotag">
            <div className="iconfont icon-info-circle" />
            <div>名词解释</div>
          </div>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">近90天武房指数</div>
              <div className="housedetail-info-item-num">{houseInfo.exponent}</div>
              <div className="housedetail-info-item-desc">
                全市第<span className="red">{houseInfo.exponentCityRank}</span>名，{houseInfo.district}区第<span className="red">{houseInfo.exponentDistrictRank}</span>名
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">总户数</div>
              <div className="housedetail-info-item-num">{houseInfo.roomTotal}套</div>
              <div className="housedetail-info-item-desc">
                高于“{houseInfo.plate}”{houseInfo.roomTotalRankRate}%的楼盘
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">报规库存</div>
              <div className="housedetail-info-item-num">{houseInfo.stockNum}套</div>
              <div className="housedetail-info-item-desc" />
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">取证库存</div>
              <div className="housedetail-info-item-num">{houseInfo.availableRoomNum}套</div>
              <div className="housedetail-info-item-desc" />
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">累计网签</div>
              <div className="housedetail-info-item-num">{houseInfo.soldTotal}套</div>
              <div className="housedetail-info-item-desc" />
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">30天网签</div>
              <div className="housedetail-info-item-num red">{houseInfo.newSoldNum}套</div>
              <div className="housedetail-info-item-desc">
                全市第<span className="red">{houseInfo.newSoldCityRank}</span>名{houseInfo.newSoldDistrictRank > 0 ? ',' + houseInfo.district + '区第' + houseInfo.newSoldDistrictRank + '名' : ''}
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">预售证</div>
              <div className="housedetail-info-item-num">{houseInfo.evidenceTotal}个</div>
              <div className="housedetail-info-item-desc">最新取证：{Dayjs(houseInfo.evidenceNewTime).format('YYYY-MM-DD')}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">项目地块</div>
              <div className="housedetail-info-item-num">{houseInfo.projectTotal}个</div>
              <div className="housedetail-info-item-desc">{houseInfo.projectAreaTotal > 0 ? '总建面：' + houseInfo.projectAreaTotal + '万方' : ''}</div>
            </div>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col span={12}>
          <Card className="card">
            <div className="card-title">
              <div className="card-txt">价格走势</div>
            </div>
            <div id="echarts-line" style={{ width: '100%', height: '300px' }} />
          </Card>
        </Col>
        <Col span={12}>
          <Card className="card" style={{ marginLeft: 0 }}>
            <div className="card-title">
              <div className="card-txt">网签量</div>
              <div className="housedetail-signtabs">
                {tabList.map((item, index) => {
                  return (
                    <div className={index == tabIndex ? 'housedetail-signtabs-item housedetail-signtabs-item-active' : 'housedetail-signtabs-item'} key={index} onClick={() => changeTabs(index)}>
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
            <div id="echarts-bar" style={{ width: newLocal, height: '300px' }} />
          </Card>
        </Col>
      </Row>
      <Card
        className="card"
        title="销控图"
        extra={
          <Button
            type="link"
            onClick={useCallback(() => {
              changeLinkTabs('/managecenter/house/soldchartlist/' + id)
            })}
          >
            详情
          </Button>
        }
      >
        {projectList.map((item, index) => {
          return (
            <div className="project-item" key={index}>
              <div className="project-name">{item.name}</div>
              <Flex gap="middle">
                {item.soldChartImg2.map((ele, index2) => {
                  return (
                    <div className="project-img" key={index2}>
                      <Image
                        src={ele.img + '?x-oss-process=image/resize,w_160,m_fill'}
                        preview={{
                          src: ele.img
                        }}
                        className="img"
                      />
                      <div className="project-date">{Dayjs(ele.time).format('MM月DD日')}</div>
                    </div>
                  )
                })}
              </Flex>
            </div>
          )
        })}
      </Card>
      <Card className="card" title="基础信息">
        <Row>
          <Col span={8}>
            <div>
              区域板块：
              <span className="gray">
                {houseInfo.district} {houseInfo.plate}
              </span>
            </div>
          </Col>
          <Col span={8}>
            <div>
              地址：<span className="gray">{houseInfo.address}</span>
            </div>
          </Col>
          <Col span={8} />
          <Col span={8}>
            <div>
              建筑类型：<span className="gray">{houseInfo.buildingType}</span>
            </div>
          </Col>
          <Col span={8}>
            <div>
              产权年限：<span className="gray">{houseInfo.ownership}</span>
            </div>
          </Col>
          <Col span={8}>
            <div>
              容积率：<span className="gray">{houseInfo.plotRatio}</span>
            </div>
          </Col>
          <Col span={8}>
            <div>
              物业费：<span className="gray">{houseInfo.propertyFee}</span>
            </div>
          </Col>
          <Col span={8}>
            <div>
              停车位：<span className="gray">{houseInfo.parkingTotal}</span>
            </div>
          </Col>
          <Col span={8}>
            <div>
              车位比<span className="gray">1: {(houseInfo.parkingTotal / houseInfo.roomTotal).toFixed(2)}</span>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
