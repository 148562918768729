import React from 'react'
import { Card, Col, Row, DatePicker } from 'antd'
import { useEffect, useState } from 'react'
import { getAllCityCountDataAPI, getAllCitySignListAPI } from '../../../../apis/house'
import Dayjs from 'dayjs'
import * as echarts from 'echarts'
const { RangePicker } = DatePicker

var myChart

export default function AllCityInfo() {
  const [countData, setCountData] = useState({})
  const [signListQuery, setSignListQuery] = useState({
    startTime: '',
    endTime: ''
  })

  //  获取统计数据
  const fetchData = async () => {
    const res = await getAllCityCountDataAPI()
    if (res.success === true) {
      if (res.result) {
        res.result.lastMonthSoldRate = res.result.lastMonthSoldNum ? Math.round(((res.result.monthSoldNum - res.result.lastMonthSoldNum) / res.result.lastMonthSoldNum) * 1000) / 10 : 0
        res.result.lastYearMonthSoldRate = res.result.lastYearMonthSoldNum ? Math.round(((res.result.monthSoldNum - res.result.lastYearMonthSoldNum) / res.result.lastYearMonthSoldNum) * 1000) / 10 : 0
        res.result.lastYearSoldRate = res.result.lastYearSoldNum ? Math.round(((res.result.yearSoldNum - res.result.lastYearSoldNum) / res.result.lastYearSoldNum) * 1000) / 10 : 0
        res.result.yearSoldAreaTotal2 = Math.round((res.result.yearSoldAreaTotal / 10000) * 100) / 100
        res.result.yearSoldAreaRate = res.result.lastYearSoldAreaTotal ? Math.round(((res.result.yearSoldAreaTotal - res.result.lastYearSoldAreaTotal) / res.result.lastYearSoldAreaTotal) * 1000) / 10 : 0
        res.result.lastYearEvidenceRate = res.result.lastYearEvidenceNum ? Math.round(((res.result.yearEvidenceNum - res.result.lastYearEvidenceNum) / res.result.lastYearEvidenceNum) * 1000) / 10 : 0
        res.result.lastyearRoomRate = res.result.lastyearRoomTotal ? Math.round(((res.result.yearRoomTotal - res.result.lastyearRoomTotal) / res.result.lastyearRoomTotal) * 1000) / 10 : 0
        res.result.yearRoomRate = res.result.yearRoomTotal ? Math.round((res.result.yearSoldNum / res.result.yearRoomTotal) * 10) / 10 : 0
        res.result.lastYearSoldAreaTotal = Math.round((res.result.lastYearSoldAreaTotal / 10000) * 100) / 100
        setCountData(res.result)
      }
    }
  }

  //  获取网签数据
  const getSignList = async signListQuery => {
    const res = await getAllCitySignListAPI(signListQuery)
    if (res.success === true) {
      if (res.result) {
        let titleList = []
        let dataList = []
        setSignListQuery({
          startTime: res.result.startTime,
          endTime: res.result.endTime
        })
        if (res.result.list) {
          let list = res.result.list.reverse()
          list.forEach(item => {
            titleList.push(item.time)
            dataList.push(item.soldNum)
          })
        }

        var option

        option = {
          color: ['#07C160'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            },
            valueFormatter: value => value + '套'
          },
          legend: {
            show: true
          },
          xAxis: {
            type: 'category',
            data: titleList,
            axisLabel: {
              formatter: function (value) {
                return Dayjs(value).date()
              }
            }
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '住宅成交量',
              data: dataList,
              type: 'bar',
              barWidth: 8
            }
          ]
        }

        option && myChart.setOption(option)
      }
    }
  }
  const changeTime = (time, timeString) => {
    signListQuery.startTime = timeString[0]
    signListQuery.endTime = timeString[1]
    setSignListQuery({
      ...signListQuery,
      startTime: timeString[0],
      endTime: timeString[1]
    })
    getSignList(signListQuery)
  }

  useEffect(() => {
    var chartDom = document.getElementById('echarts-bar')
    myChart = echarts.init(chartDom)
    fetchData()
    getSignList()
  }, [])

  return (
    <div>
      <Card
        className="card"
        title="核心数据"
        extra={
          <div className="housedetail-infotag">
            <div className="iconfont icon-info-circle" />
            <div>名词解释</div>
          </div>
        }
      >
        <div className="row-title" style={{ marginTop: '0' }}>
          成交
        </div>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">日成交</div>
              <div className="housedetail-info-item-num">{countData.daySoldNum}套</div>
              <div className="housedetail-info-item-desc">{countData.soldTime}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">{Dayjs(countData.soldTime).month() + 1}月成交量</div>
              <div className="housedetail-info-item-num">{countData.monthSoldNum}套</div>
              <div className="housedetail-info-item-desc">
                环比上月<span className={countData.lastMonthSoldRate > 0 ? 'red' : 'green'}>{countData.lastMonthSoldRate}%</span>，同比去年<span className={countData.lastYearMonthSoldRate > 0 ? 'red' : 'green'}>{countData.lastYearMonthSoldRate}%</span>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">{Dayjs(countData.soldTime).year()}年成交量</div>
              <div className="housedetail-info-item-num">{countData.yearSoldNum}套</div>
              <div className="housedetail-info-item-desc">
                去年同期{countData.lastYearSoldNum}套，同比<span className={countData.lastYearSoldRate > 0 ? 'red' : 'green'}>{countData.lastYearSoldRate}%</span>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">{Dayjs(countData.soldTime).year()}年成交面积</div>
              <div className="housedetail-info-item-num">{countData.yearSoldAreaTotal2}万方</div>
              <div className="housedetail-info-item-desc">
                去年同期{countData.lastYearSoldAreaTotal}万方，同比<span className={countData.yearSoldAreaRate > 0 ? 'red' : 'green'}>{countData.yearSoldAreaRate}%</span>
              </div>
            </div>
          </Col>
        </Row>
        <div className="row-title">供应</div>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">{Dayjs(countData.evidenceTime).month() + 1}月取证数</div>
              <div className="housedetail-info-item-num">{countData.monthEvidenceNum}个</div>
              <div className="housedetail-info-item-desc">上月取证{countData.lastMonthEvidenceNum}个</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">{Dayjs(countData.evidenceTime).year()}年取证数</div>
              <div className="housedetail-info-item-num">{countData.yearEvidenceNum}个</div>
              <div className="housedetail-info-item-desc">
                去年同期{countData.lastYearEvidenceNum}个，同比<span className={countData.lastYearEvidenceRate > 0 ? 'red' : 'green'}>{countData.lastYearEvidenceRate}%</span>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">取证房源量</div>
              <div className="housedetail-info-item-num">{countData.yearRoomTotal}套</div>
              <div className="housedetail-info-item-desc">
                去年同期{countData.lastyearRoomTotal}套，同比<span className={countData.lastyearRoomRate > 0 ? 'red' : 'green'}>{countData.lastyearRoomRate}%</span>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">{Dayjs(countData.evidenceTime).year()}年供销比</div>
              <div className="housedetail-info-item-num">1:{countData.yearRoomRate}</div>
              <div className="housedetail-info-item-desc">{countData.yearRoomRate > 1 ? '销量大于供应' : '供应大于销量'}</div>
            </div>
          </Col>
        </Row>
        <div className="row-title">库存</div>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">报规库存</div>
              <div className="housedetail-info-item-num">{countData.stockNum}套</div>
              <div className="housedetail-info-item-desc">{countData.soldTime}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">取证库存</div>
              <div className="housedetail-info-item-num">{countData.roomTotal}套</div>
              <div className="housedetail-info-item-desc">{countData.evidenceTime}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">月均销量</div>
              <div className="housedetail-info-item-num">{countData.monthAvgSoldNum}套</div>
              <div className="housedetail-info-item-desc">近6个完整月销量平均</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="housedetail-info-item">
              <div className="housedetail-info-item-title">取证库存去化周期</div>
              <div className="housedetail-info-item-num">{countData.evidenceSoldCycle}月</div>
              <div className="housedetail-info-item-desc" />
            </div>
          </Col>
        </Row>
      </Card>
      <Card className="card">
        <div className="card-title">
          <div className="card-txt">全市新房住宅成交量走势</div>
          <div className="housedetail-signtabs">
            <RangePicker allowClear needConfirm picker="date" value={[Dayjs(signListQuery.startTime), Dayjs(signListQuery.endTime)]} style={{ width: '300px' }} onChange={changeTime} />
          </div>
        </div>
        <div id="echarts-bar" style={{ width: '100%', height: '300px', marginTop: '24px' }} />
      </Card>
    </div>
  )
}
