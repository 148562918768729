//  公共请求
import { request } from '../utils'

//  区域板块列表
export function getDistrictListAPI(data) {
  return request({
    url: '/data/GetDistrictData',
    method: 'POST',
    data
  })
}
