import React, { useRef, useEffect, useState } from 'react'
import { Card, Form, Button, Select } from 'antd'
import { searchHouseAPI, getHouseTreeData2API } from '../../../apis/house'
import * as echarts from 'echarts'

export default function HouseTree() {
  const chartRef = useRef(null)
  let myChart
  const [searchHouseList, setSearchHouseList] = useState([
    {
      value: 334501106552909,
      text: '城投融创国博城'
    }
  ]) //  表格加载状态
  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    houseid: 334501106552909
  })
  //  楼盘树数据
  const searchFinish = async () => {
    console.log('%c [ searchHouseList ]-10', 'font-size:13px; background:pink; color:#bf2c9f;', searchHouseList)
    myChart = echarts.init(chartRef.current)
    var option
    myChart.showLoading()
    const res = await getHouseTreeData2API(listQuery)
    if (res.success === true) {
      let data = {}
      if (res.result) {
        data.name = res.result.houseName
        data.id = res.result.houseId
        if (res.result.relatedList) {
          const r = await getHouseTreeData2API({ houseid: res.result.relatedList[0].houseId })
          if (r.success === true) {
            myChart.hideLoading()
            let secondChild = []
            if (r.result) {
              if (r.result.relatedList) {
                r.result.relatedList.forEach(ele => {
                  let tmp = {
                    id: ele.houseId,
                    name: ele.houseName,
                    data: Math.round((ele.viewNum / r.result.viewNum) * 100) + '%'
                  }
                  let gap = ele.salePrice - r.result.salePrice
                  if (gap <= 500 && gap >= -500) {
                    tmp.gap = '同价位'
                  }
                  if (ele.plateId === r.result.plateId) {
                    tmp.plateType = '同板块'
                  } else if (ele.districtId === r.result.districtId) {
                    tmp.plateType = '同区域'
                  }
                  secondChild.push(tmp)
                })
              }
            }

            res.result.relatedList.forEach((item, index) => {
              let child = {
                id: item.houseId,
                name: item.houseName,
                data: Math.round((item.viewNum / res.result.viewNum) * 100) + '%',
                children: [{}],
                collapsed: true
              }
              let gap = item.salePrice - res.result.salePrice
              if (gap <= 500 && gap >= -500) {
                child.gap = '同价位'
              }
              if (item.plateId == res.result.plateId) {
                child.plateType = '同板块'
              } else if (item.districtId == res.result.districtId) {
                child.plateType = '同区域'
              }

              if (index === 0) {
                child.collapsed = false
                if (secondChild) {
                  child.children = secondChild
                }
              }

              if (!data.children) {
                data.children = []
              }
              data.children.push(child)
            })

            myChart.setOption(
              (option = {
                tooltip: {
                  show: false
                },
                series: [
                  {
                    type: 'tree',
                    data: [data],
                    top: '1%',
                    left: '7%',
                    bottom: '1%',
                    right: '20%',
                    symbolSize: 7,
                    itemStyle: {
                      color: '#07C160'
                    },
                    expandLevel: 1,
                    label: {
                      position: 'left',
                      verticalAlign: 'middle',
                      align: 'right',
                      color: '#262626',
                      fontSize: 12,
                      lineHeight: 48,
                      overflow: 'break',
                      with: 100,
                      formatter: function (value) {
                        if (value.data.data) {
                          if (value.data.gap) {
                            if (value.data.plateType) {
                              return `{rate|${value.data.data}} {name|${value.data.name}} {gap|${value.data.gap}} {plateType|${value.data.plateType}}`
                            } else {
                              return `{rate|${value.data.data}} {name|${value.data.name}} {gap|${value.data.gap}}`
                            }
                          } else {
                            if (value.data.plateType) {
                              return `{rate|${value.data.data}} {name|${value.data.name}} {plateType|${value.data.plateType}}`
                            } else {
                              return `{rate|${value.data.data}} {name|${value.data.name}}`
                            }
                          }
                        } else {
                          if (value.data.gap) {
                            if (value.data.plateType) {
                              return `{name|${value.data.name}} {gap|${value.data.gap}} {plateType|${value.data.plateType}}`
                            } else {
                              return `{name|${value.data.name}} {gap|${value.data.gap}}`
                            }
                          } else {
                            if (value.data.plateType) {
                              return `{name|${value.data.name}} {plateType|${value.data.plateType}}`
                            } else {
                              return `{name|${value.data.name}}`
                            }
                          }
                        }
                      },
                      rich: {
                        rate: {
                          backgroundColor: '#E6F8EF',
                          color: '#07C160',
                          padding: [5, 5],
                          borderRadius: 4
                        },
                        name: {
                          color: '#262626'
                        },
                        gap: {
                          backgroundColor: '#F2F2F2',
                          color: '#666666',
                          padding: [5, 5],
                          borderRadius: 4
                        },
                        plateType: {
                          backgroundColor: '#F2F2F2',
                          color: '#666666',
                          padding: [5, 5],
                          borderRadius: 4
                        }
                      }
                    },
                    lineStyle: {
                      color: '#f2f2f2'
                    },
                    leaves: {
                      label: {
                        position: 'left',
                        verticalAlign: 'middle',
                        align: 'left',
                        lineHeight: 48
                      }
                    },
                    selectedMode: 'single',
                    expandAndCollapse: true,
                    animationDuration: 550,
                    animationDurationUpdate: 750
                  }
                ]
              })
            )
            option && myChart.setOption(option)

            // 监听点击事件
            myChart.on('click', async params => {
              myChart.showLoading()
              if (params.data.children.length > 1) {
                myChart.hideLoading()
                // 如果点击的节点有子节点，则收起其他同级节点
                var myChartData1 = myChart.getOption().series[0].data[0]
                expandNode(myChartData1, params.data, true)

                // 展开当前节点
                params.data.collapsed = false

                // 更新图表
                myChart.setOption({
                  series: [
                    {
                      data: [myChartData1]
                    }
                  ]
                })
              } else {
                const result = await getHouseTreeData2API({ houseid: params.data.id })
                if (result.success === true) {
                  myChart.hideLoading()
                  let nextChild = []
                  if (result.result) {
                    if (result.result.relatedList) {
                      result.result.relatedList.forEach(ele => {
                        let tmp = {
                          id: ele.houseId,
                          name: ele.houseName,
                          data: Math.round((ele.viewNum / result.result.viewNum) * 100) + '%'
                        }
                        let gap = ele.salePrice - result.result.salePrice
                        if (gap <= 500 && gap >= -500) {
                          tmp.gap = '同价位'
                        }
                        if (ele.plateId == result.result.plateId) {
                          tmp.plateType = '同板块'
                        } else if (ele.districtId == result.result.districtId) {
                          tmp.plateType = '同区域'
                        }
                        nextChild.push(tmp)
                      })
                      params.data.children = nextChild
                    }
                  }
                  if (params.data.children) {
                    // 如果点击的节点有子节点，则收起其他同级节点
                    var myChartData2 = myChart.getOption().series[0].data[0]
                    expandNode(myChartData2, params.data, true)

                    // 展开当前节点
                    params.data.collapsed = false

                    // 更新图表
                    myChart.setOption({
                      series: [
                        {
                          data: [myChartData2]
                        }
                      ]
                    })
                  }
                }
              }
            })
          }
        }
      }
    }
  }

  const expandNode = (node, target, collapse) => {
    if (node.children) {
      node.children.forEach(function (child) {
        if (child.id !== target.id) {
          child.collapsed = collapse
        } else {
          child.collapsed = !collapse
        }
        expandNode(child, target, collapse)
      })
    }
  }

  let timeout
  let currentValue
  const fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    const fake = async () => {
      const res = await searchHouseAPI({ keywords: value })
      if (res.success === true) {
        if (currentValue === value) {
          const searchHouseList = res.result.map(item => ({
            value: item.id,
            text: item.name
          }))
          callback(searchHouseList)
        }
      }
    }
    if (value) {
      timeout = setTimeout(fake, 300)
    } else {
      callback([])
    }
  }
  const handleSearch = newValue => {
    fetch(newValue, setSearchHouseList)
  }
  const handleChange = newValue => {
    setListQuery({
      ...listQuery,
      houseid: newValue
    })
  }

  useEffect(() => {
    searchFinish()
  }, [])
  return (
    <Card className="card">
      <div style={{ background: '#FAFAFA', padding: '8px 16px', fontSize: '14px', color: '#666666', lineHeight: '22px', borderRadius: '2px' }}>
        根据武房通近90天用户大数据生成，仅供参考！（百分比：指关注主楼盘的用户中，同时关注相关楼盘的比例）
        <br />
        同价位：参考单价类似；同区域：行政区相同，板块不同；同板块：行政区和板块都相同。
      </div>
      <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm" style={{ marginTop: '24px' }}>
        <Form.Item name="houseid">
          <Select
            allowClear
            showSearch
            placeholder="楼盘名称关键词"
            style={{ width: '200px' }}
            defaultActiveFirstOption={true}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={(searchHouseList || []).map(d => ({
              value: d.value,
              label: d.text
            }))}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
            查询
          </Button>
        </Form.Item>
      </Form>
      <div ref={chartRef} style={{ width: '100%', height: '1000px' }} />
    </Card>
  )
}
