import React from 'react'
import { Card, Flex, Image } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseSoldChartListAPI } from '../../../../apis/house'
import { useParams } from 'react-router-dom'
import Dayjs from 'dayjs'
export default function SoldChartList() {
  const location = useParams()
  const id = location.id
  const [projectList, setProjectList] = useState([])

  //  销控图列表
  const fetchData = async () => {
    const res = await getHouseSoldChartListAPI({ id })
    console.log('%c [ res ]-26', 'font-size:13px; background:pink; color:#bf2c9f;', res)
    if (res.success === true) {
      if (res.result.list) {
        setProjectList(res.result.list)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Card className="card">
      {projectList.map((item, index) => {
        return (
          <div className="project-item" key={index}>
            <div className="project-name">{item.name}</div>
            <Flex wrap="wrap" gap="middle">
              <Image.PreviewGroup>
                {item.soldChartImg.map((ele, index2) => {
                  return (
                    <div className="project-img" key={index2}>
                      <Image
                        key={index2}
                        width={160}
                        height={240}
                        src={ele.img + '?x-oss-process=image/resize,w_160,m_fill'}
                        preview={{
                          src: ele.img,
                          current: index2,
                          images: item.soldChartImg
                        }}
                        className="img"
                      />
                      <div className="project-date">{Dayjs(ele.time).format('MM月DD日')}</div>
                    </div>
                  )
                })}
              </Image.PreviewGroup>
            </Flex>
          </div>
        )
      })}
    </Card>
  )
}
