import React from 'react'
import { Tabs } from 'antd'
import AllCityInfo from './components/allCityInfo'
import SignLog from './components/signLog'
import './index.scss'
const tabsList = [
  {
    key: '1',
    label: '概况',
    children: <AllCityInfo />
  },
  {
    key: '2',
    label: '历年成交',
    children: <SignLog />
  }
]

export default function Index() {
  return (
    <div>
      <div className="pageTitle">
        <div className="pageTitle-name">全市供销存（住宅）</div>
      </div>
      <div className="tabs">
        <Tabs defaultActiveKey="1" items={tabsList} />
      </div>
    </div>
  )
}
