//  封装高阶组件  核心逻辑：有token正常跳转，无token去登录
import React from 'react'
import { getToken } from '../utils'
import { Navigate } from 'react-router-dom'

export function AuthRoute(info) {
  const token = getToken()
  if (token) {
    return <>{info.children}</>
  } else {
    return <Navigate to={'/'} replace />
  }
}
