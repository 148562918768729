import React from 'react'
import { Card, Table, Form, Input, Space, Button, Pagination, DatePicker } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseEvidenceListAPI } from '../../../apis/house'
// import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Dayjs from 'dayjs'
const { Column } = Table
const { RangePicker } = DatePicker

export default function EvidenceList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态

  //   const location = useParams()
  //   const id = location.id

  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    houseName: '',
    evidenceName: '',
    evidenceStartTime: '',
    evidenceEndTime: '',
    avgPriceStart: '',
    avgPriceEnd: '',
    soldRateStart: '',
    soldRateEnd: '',
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [list, setList] = useState()

  //  获取楼盘户型列表
  const fetchData = async () => {
    setListLoading(true)
    listQuery.avgPriceStart = Number(listQuery.avgPriceStart)
    listQuery.avgPriceEnd = Number(listQuery.avgPriceEnd)
    listQuery.soldRateStart = Number(listQuery.soldRateStart)
    listQuery.soldRateEnd = Number(listQuery.soldRateEnd)
    const res = await getHouseEvidenceListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach((item, index) => {
          item.soldRate = item.soldRate ? Math.round(item.soldRate * 100, 2) + '%' : ''
          item.time = Dayjs(item.time).format('YYYY-MM-DD')
          item.avgPrice = item.avgPrice ? Math.round(item.avgPrice) + '元/平' : ''
          item.key = index + 1
        })
      }
      setList(res.result.list)
      setTotal(res.result.total)
      setListLoading(false)
    }
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }
  // 搜索
  const searchFinish = values => {
    console.log('%c [ values ]-152', 'font-size:13px; background:pink; color:#bf2c9f;', values)
    listQuery.houseName = values.houseName
    listQuery.evidenceName = values.evidenceName
    let evidenceStartTime = ''
    let evidenceEndTime = ''
    if (values.evidenceEndTime) {
      evidenceStartTime = Dayjs(values.evidenceEndTime[0]).format('YYYY-MM-DD')
      evidenceEndTime = Dayjs(values.evidenceEndTime[1]).format('YYYY-MM-DD')
    }
    listQuery.evidenceStartTime = evidenceStartTime
    listQuery.evidenceEndTime = evidenceEndTime

    let avgPriceEnd = Number(values.avgPriceEnd)
    let avgPriceStart = Number(values.avgPriceStart)
    if (avgPriceEnd < avgPriceStart) {
      avgPriceStart = Number(values.avgPriceStart)
      avgPriceEnd = Number(values.avgPriceEnd)
    }
    listQuery.avgPriceStart = avgPriceStart
    listQuery.avgPriceEnd = avgPriceEnd

    let soldRateEnd = Number(values.soldRateEnd)
    let soldRateStart = Number(values.soldRateStart)
    if (soldRateEnd < soldRateStart) {
      soldRateStart = Number(values.soldRateStart)
      soldRateEnd = Number(values.soldRateEnd)
    }
    listQuery.soldRateStart = soldRateStart
    listQuery.soldRateEnd = soldRateEnd
    listQuery.pageInt = 1
    setListQuery({
      ...listQuery
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Card className="card">
      <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm">
        <Form.Item name="houseName">
          <Input allowClear placeholder="楼盘名称" style={{ width: '200px', marginLeft: '10px' }} />
        </Form.Item>
        <Form.Item name="evidenceName">
          <Input allowClear placeholder="预售证号" style={{ width: '200px', marginLeft: '10px' }} />
        </Form.Item>
        <Form.Item name="evidenceEndTime">
          <RangePicker allowClear needConfirm picker="date" placeholder={['取证-开始时间', '取证-结束时间']} style={{ width: '300px', marginLeft: '10px' }} />
        </Form.Item>
        <Space.Compact size="middle" style={{ width: '300px', marginLeft: '10px' }}>
          <Form.Item name="avgPriceStart">
            <Input addonAfter="元" placeholder="起始均价" />
          </Form.Item>
          <Form.Item name="avgPriceEnd">
            <Input addonAfter="元" placeholder="截止均价" />
          </Form.Item>
        </Space.Compact>
        <Space.Compact size="middle" style={{ width: '300px', marginLeft: '10px' }}>
          <Form.Item name="soldRateStart">
            <Input addonAfter="%" placeholder="起始去化" />
          </Form.Item>
          <Form.Item name="soldRateEnd">
            <Input addonAfter="%" placeholder="截止去化" />
          </Form.Item>
        </Space.Compact>
        <Form.Item>
          <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
            搜索
          </Button>
        </Form.Item>
      </Form>
      <Table dataSource={list} loading={listLoading} pagination={false}>
        <Column title="预售证" dataIndex="name" />
        <Column title="楼盘名称" dataIndex="houseName" />
        <Column title="取证时间" dataIndex="time" />
        <Column title="备案均价" dataIndex="avgPrice" />
        <Column title="房源" dataIndex="roomNum" />
        <Column title="已售" dataIndex="soldNum" className="red" />
        <Column title="去化率" dataIndex="soldRate" className="red" />
        <Column title="未售" dataIndex="availableRoomNum" className="green" />
        <Column title="更新时间" dataIndex="updateTime" />
        <Column
          title="操作"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Link to={'/managecenter/house/detail/' + record.id}>详情</Link>
              <Link to={'/managecenter/house/detail/' + record.id}>一房一价数据包</Link>
            </Space>
          )}
        />
      </Table>
      <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
    </Card>
  )
}
