import React from 'react'
import { Card, Col, Row, Table, Form, Input, Select, Space, Button, Pagination } from 'antd'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getHouseRoomListAPI, getHouseEvidenceListAPI, getRoomCountDataAPI } from '../../../../apis/house'
import Dayjs from 'dayjs'
const { Column } = Table

const tabList = ['总价', '单价', '面积']

export default function RoomList() {
  const location = useParams()
  const id = location.id

  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  const [listQuery, setListQuery] = useState({
    pageInt: 1,
    pageSize: 10,
    houseId: id, //	楼盘编号
    evidenceId: null, //	预售证编号
    saleStatus: null, // 销售状态
    totalPriceStart: null, //	起始总价
    totalPriceEnd: null, //	截止总价
    priceStart: null, //	起始单价
    priceEnd: null //	截止单价
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [list, setList] = useState(0) //  列表总数
  const [evidenceList, setEvidenceList] = useState([]) //  预售证列表
  const [saleStatusList] = useState([
    { value: 1, label: '已售' },
    { value: 2, label: '未售' }
  ])

  const [allData, setAllData] = useState({})
  const [newTabIndex, setNewTabIndex] = useState(0)
  const [maxNewEvidenceMun, setMaxNewEvidenceMun] = useState(0) //  最新取证房源最大数据
  const [newEvidenceData, setNewEvidenceData] = useState([]) //  最新取证房源分布数据
  const [allTabIndex, setAllTabIndex] = useState(0)
  const [maxAllRoomNum, setMaxAllRoomNum] = useState(0) //  全部房源最大数据
  const [allRoomData, setAllRoomData] = useState([]) //  全部房源分布数据

  //  获取楼盘户型列表
  const fetchData = async () => {
    setListLoading(true)
    listQuery.evidenceId = Number(listQuery.evidenceId)
    listQuery.saleStatus = Number(listQuery.saleStatus)
    listQuery.totalPriceStart = Number(listQuery.totalPriceStart)
    listQuery.totalPriceEnd = Number(listQuery.totalPriceEnd)
    listQuery.priceStart = Number(listQuery.priceStart)
    listQuery.priceEnd = Number(listQuery.priceEnd)
    const res = await getHouseRoomListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach(item => {
          item.soldTime = Dayjs(item.soldTime).format('YYYY-MM-DD')
          item.saleStatus2 = item.saleStatus == 1 ? '已售' : item.saleStatus == 2 ? '未售' : ''
          item.unitName = item.unitName == '/' ? '' : item.unitName + '单元'
        })
      }
      setList(res.result.list)
      setTotal(res.result.total)
      setListLoading(false)
    }
  }
  //  获取预售证列表
  const getEvidenceList = async () => {
    const res = await getHouseEvidenceListAPI({ houseId: id })
    if (res.success === true) {
      if (res.result.list) {
        let tmplist = []
        res.result.list.forEach(item => {
          tmplist.push({
            title: item.name,
            value: item.id,
            label: item.name
          })
        })
        setEvidenceList(tmplist)
      }
    }
  }
  //  获取房源分布数据
  const getRoomCountData = async () => {
    const res = await getRoomCountDataAPI({ id })
    console.log('%c [ res ]-76', 'font-size:13px; background:pink; color:#bf2c9f;', res)
    if (res.success === true) {
      setAllData(res.result)
      if (res.result.totalPriceList) {
        let maxNewEvidenceMun = 0
        res.result.totalPriceList.forEach(item => {
          maxNewEvidenceMun = Math.max(item.soldRoomNum + item.availableRoomNum, maxNewEvidenceMun)
        })
        setMaxNewEvidenceMun(maxNewEvidenceMun)
      }
      setNewEvidenceData(res.result.totalPriceList)
      if (res.result.allTotalPriceList) {
        let maxAllRoomNum = 0
        res.result.allTotalPriceList.forEach(item => {
          maxAllRoomNum = Math.max(item.soldRoomNum + item.availableRoomNum, maxAllRoomNum)
        })
        setMaxAllRoomNum(maxAllRoomNum)
      }
      setAllRoomData(res.result.allTotalPriceList)
    }
  }
  //  最新取证房源Tabs切换
  const changeNewTabs = index => {
    if (newTabIndex === index) {
      return false
    }
    if (index === 2) {
      if (allData.areaList) {
        let maxNewEvidenceMun = 0
        allData.areaList.forEach(item => {
          maxNewEvidenceMun = Math.max(item.soldRoomNum + item.availableRoomNum, maxNewEvidenceMun)
        })
        setMaxNewEvidenceMun(maxNewEvidenceMun)
      }
      setNewEvidenceData(allData.areaList)
    } else if (index === 1) {
      if (allData.unitPriceList) {
        let maxNewEvidenceMun = 0
        allData.unitPriceList.forEach(item => {
          maxNewEvidenceMun = Math.max(item.soldRoomNum + item.availableRoomNum, maxNewEvidenceMun)
        })
        setMaxNewEvidenceMun(maxNewEvidenceMun)
      }
      setNewEvidenceData(allData.unitPriceList)
    } else {
      if (allData.totalPriceList) {
        let maxNewEvidenceMun = 0
        allData.totalPriceList.forEach(item => {
          maxNewEvidenceMun = Math.max(item.soldRoomNum + item.availableRoomNum, maxNewEvidenceMun)
        })
        setMaxNewEvidenceMun(maxNewEvidenceMun)
      }
      setNewEvidenceData(allData.totalPriceList)
    }
    setNewTabIndex(index)
  }
  //  全部取证房源Tabs切换
  const changeAllTabs = index => {
    if (allTabIndex === index) {
      return false
    }
    if (index === 2) {
      if (allData.allAreaList) {
        let maxAllRoomNum = 0
        allData.allAreaList.forEach(item => {
          maxAllRoomNum = Math.max(item.soldRoomNum + item.availableRoomNum, maxAllRoomNum)
        })
        setMaxAllRoomNum(maxAllRoomNum)
      }
      setAllRoomData(allData.allAreaList)
    } else if (index === 1) {
      if (allData.allUnitPriceList) {
        let maxAllRoomNum = 0
        allData.allUnitPriceList.forEach(item => {
          maxAllRoomNum = Math.max(item.soldRoomNum + item.availableRoomNum, maxAllRoomNum)
        })
        setMaxAllRoomNum(maxAllRoomNum)
      }
      setAllRoomData(allData.allUnitPriceList)
    } else {
      if (allData.allTotalPriceList) {
        let maxAllRoomNum = 0
        allData.allTotalPriceList.forEach(item => {
          maxAllRoomNum = Math.max(item.soldRoomNum + item.availableRoomNum, maxAllRoomNum)
        })
        setMaxAllRoomNum(maxAllRoomNum)
      }
      setAllRoomData(allData.allTotalPriceList)
    }
    setAllTabIndex(index)
  }
  // 搜索
  const searchFinish = values => {
    listQuery.evidenceId = Number(values.evidenceId)
    listQuery.saleStatus = values.saleStatus

    let totalPriceEnd = Number(values.totalPriceEnd)
    let totalPriceStart = Number(values.totalPriceStart)
    if (totalPriceEnd < totalPriceStart) {
      totalPriceStart = Number(values.totalPriceStart)
      totalPriceEnd = Number(values.totalPriceEnd)
    }
    listQuery.totalPriceStart = totalPriceStart
    listQuery.totalPriceEnd = totalPriceEnd

    let priceEnd = Number(values.priceEnd)
    let priceStart = Number(values.priceStart)
    if (priceEnd < priceStart) {
      priceStart = Number(values.priceStart)
      priceEnd = Number(values.priceEnd)
    }
    listQuery.priceStart = priceStart
    listQuery.priceEnd = priceEnd
    listQuery.pageInt = 1
    setListQuery({
      ...listQuery
    })
    fetchData()
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }
  useEffect(() => {
    getEvidenceList()
    getRoomCountData()
    fetchData()
  }, [])

  return (
    <div>
      <Row>
        <Col span={12}>
          <Card className="card">
            <div className="card-title">
              <div className="card-txt">最新取证房源分布</div>
              <div className="housedetail-signtabs">
                {tabList.map((item, index) => {
                  return (
                    <div className={index == newTabIndex ? 'housedetail-signtabs-item housedetail-signtabs-item-active' : 'housedetail-signtabs-item'} key={index} onClick={() => changeNewTabs(index)}>
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="roomlist-count">
              <div className="roomlist-type">
                <div className="roomlist-type-item">
                  <div className="roomlist-type-item-color bg-red" />
                  <div className="roomlist-type-item-txt">已售</div>
                </div>
                <div className="roomlist-type-item">
                  <div className="roomlist-type-item-color bg-green" />
                  <div className="roomlist-type-item-txt">未售</div>
                </div>
              </div>
              <div className="roomlist-list">
                {newEvidenceData.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sold" style={{ width: 'calc(' + Math.round((item.soldRoomNum / maxNewEvidenceMun) * 100) + '% - ' + Math.round((item.soldRoomNum / maxNewEvidenceMun) * 200) + 'px)' }} />
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.availableRoomNum / maxNewEvidenceMun) * 100) + '% - ' + Math.round((item.availableRoomNum / maxNewEvidenceMun) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.soldRoomNum + ' / ' + item.availableRoomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="card" style={{ marginLeft: 0 }}>
            <div className="card-title">
              <div className="card-txt">全部房源分布</div>
              <div className="housedetail-signtabs">
                {tabList.map((item, index) => {
                  return (
                    <div className={index == allTabIndex ? 'housedetail-signtabs-item housedetail-signtabs-item-active' : 'housedetail-signtabs-item'} key={index} onClick={() => changeAllTabs(index)}>
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="roomlist-count">
              <div className="roomlist-type">
                <div className="roomlist-type-item">
                  <div className="roomlist-type-item-color bg-red" />
                  <div className="roomlist-type-item-txt">已售</div>
                </div>
                <div className="roomlist-type-item">
                  <div className="roomlist-type-item-color bg-green" />
                  <div className="roomlist-type-item-txt">未售</div>
                </div>
              </div>
              <div className="roomlist-list">
                {allRoomData.map((item, index) => {
                  return (
                    <div className="roomlist-item" key={index}>
                      <div className="roomlist-item-name">{item.range}</div>
                      <div className="roomlist-item-sold" style={{ width: 'calc(' + Math.round((item.soldRoomNum / maxAllRoomNum) * 100) + '% - ' + Math.round((item.soldRoomNum / maxAllRoomNum) * 200) + 'px)' }} />
                      <div className="roomlist-item-sale" style={{ width: 'calc(' + Math.round((item.availableRoomNum / maxAllRoomNum) * 100) + '% - ' + Math.round((item.availableRoomNum / maxAllRoomNum) * 200) + 'px)' }} />
                      <div className="roomlist-item-num">{item.soldRoomNum + ' / ' + item.availableRoomNum}套</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Card className="card">
        <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm">
          <Form.Item name="evidenceId">
            <Select allowClear options={evidenceList} placeholder="请选择预售证" style={{ width: '250px' }} />
          </Form.Item>
          <Form.Item name="saleStatus">
            <Select allowClear options={saleStatusList} placeholder="请选择房源状态" style={{ width: '250px', marginLeft: '10px' }} />
          </Form.Item>
          <Space.Compact size="middle" style={{ width: '300px', marginLeft: '10px' }}>
            <Form.Item name="totalPriceStart">
              <Input addonAfter="万元" placeholder="起始总价" />
            </Form.Item>
            <Form.Item name="totalPriceEnd">
              <Input addonAfter="万元" placeholder="截止总价" />
            </Form.Item>
          </Space.Compact>
          <Space.Compact size="middle" style={{ width: '300px', marginLeft: '10px' }}>
            <Form.Item name="priceStart">
              <Input addonAfter="元" placeholder="起始均价" />
            </Form.Item>
            <Form.Item name="priceEnd">
              <Input addonAfter="元" placeholder="截止均价" />
            </Form.Item>
          </Space.Compact>
          <Form.Item>
            <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
              搜索
            </Button>
          </Form.Item>
        </Form>
        <Table dataSource={list} loading={listLoading} pagination={false} rowKey="id">
          <Column title="预售证" dataIndex="evidenceName" />
          <Column title="楼栋" dataIndex="buildName" />
          <Column title="单元" dataIndex="unitName" />
          <Column title="楼层" dataIndex="floor" />
          <Column title="房号" dataIndex="name" />
          <Column title="面积" dataIndex="area" />
          <Column title="单价" dataIndex="price" />
          <Column title="总价" dataIndex="totalPrice" />
          <Column title="交付标准" dataIndex="delivery" />
          <Column title="状态" dataIndex="saleStatus2" />
          <Column title="状态更新时间" dataIndex="soldTime" />
        </Table>
        <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
      </Card>
    </div>
  )
}
