import React, { useState } from 'react'
import { Layout, Menu, Button, ConfigProvider, Tag } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined, DatabaseOutlined, PayCircleOutlined, TrophyOutlined, FundProjectionScreenOutlined, ContactsOutlined, AppstoreAddOutlined } from '@ant-design/icons'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styles from './index.module.scss'
import './index.scss'
import { getUserInfo } from '../../utils'
//  引入antd汉化包
import zhCN from 'antd/locale/zh_CN'
const { Header, Sider, Content } = Layout
const items = [
  {
    label: '基础数据库',
    icon: <DatabaseOutlined />,
    key: '1',
    children: [
      { label: '楼盘', key: '/managecenter/house' },
      { label: '预售证', key: '/managecenter/evidence' },
      { label: '项目地块', key: '/managecenter/project' },
      { label: '开发商', key: '/managecenter/developers' },
      { label: '品牌', key: '/managecenter/brand' }
    ]
  },
  {
    label: '竞品',
    icon: <AppstoreAddOutlined />,
    key: '2',
    children: [{ label: '竞品雷达', key: '/managecenter/housetree' }]
  },
  {
    label: '供销存',
    icon: <PayCircleOutlined />,
    key: '3',
    children: [
      { label: '全市', key: '/managecenter/supplymarket' },
      { label: '区域', key: '/managecenter/supplymarket/district' },
      { label: '实时网签', key: '/managecenter/supplymarket/monitor' },
      { label: '销控图', key: '/managecenter/supplymarket/soldchart' },
      { label: '库存地图', key: '/managecenter/supplymarket/stockmap' }
    ]
  },
  {
    label: '榜单',
    icon: <TrophyOutlined />,
    key: '4',
    children: [{ label: '武房通榜单', key: '/managecenter/rank' }]
  },
  {
    label: '数据大屏',
    icon: <FundProjectionScreenOutlined />,
    key: '5'
  },
  {
    label: '购房者研究',
    icon: <ContactsOutlined />,
    key: '6'
  }
]

const ManageCenter = () => {
  //  标签
  const [navTab, setNavTab] = useState([]) //  数组
  const [navTabIndex, setNavTabIndex] = useState(0) //  选中的标签

  //    菜单转成kv结构，方便使用
  const menuObj = {}
  items.forEach(item => {
    if (!item.children) {
      return
    }

    item.children.forEach(item2 => {
      item2.key && (menuObj[item2.key] = item2.label)
    })
  })

  const [collapsed, setCollapsed] = useState(false)
  const userinfo = getUserInfo()

  const navigate = useNavigate()

  const onMenuClick = ({ key }) => {
    console.log('%c [ key ]-74', 'font-size:13px; background:pink; color:#bf2c9f;', key)
    navigate(key)

    //  标签
    tabDo(key)
  }

  //    tab计算
  const tabDo = key => {
    let tabIndex = navTab.findIndex(item => item.key === key)
    if (tabIndex !== -1) {
      setNavTabIndex(tabIndex)
      return
    }

    //  根据标签，获取对应的
    let label = menuObj[key]
    if (!label) {
      return
    }

    setNavTab([...navTab, { label, key }])
    setNavTabIndex(navTab.length) //  navTab变化好像有延迟，所以这里没有-1 todo...
  }
  const tabDel = index => {
    let tab = navTab[index]
    if (!tab || navTab.length < 2) {
      return //  只有1个不让删除
    }

    navTab.splice(index, 1)
    setNavTab([...navTab])

    //  如果关闭的是当前显示的，则切换到后一个
    if (index !== navTabIndex) {
      if (navTabIndex > index) {
        setNavTabIndex(navTabIndex - 1)
      }
      return
    }

    tabDo(navTab[navTab.length - 1].key)
  }

  //  菜单高亮
  const location = useLocation()
  const selectedKey = location.pathname
  //   tabDo(selectedKey) //  初始化 todo...

  let allKeys = items.map(item => item.key)

  return (
    <ConfigProvider locale={zhCN}>
      <Layout className="page flex" hasSider={true}>
        <div className={styles['left-menu']}>
          <Sider className="left h-full" trigger={null} collapsible collapsed={collapsed}>
            {collapsed ? <img src="/images/logo-small.png" className="logo" /> : <img src="/images/logo.png" className="logo" />}

            <div className="menu">
              <Menu defaultOpenKeys={allKeys} mode="inline" selectedKeys={selectedKey} items={items} onClick={onMenuClick} style={{ background: '#EEF0F4' }} />
            </div>
          </Sider>
        </div>
        <div className="right-main flex-1">
          <Layout className="right h-full">
            <Header className="userinfo">
              <div className="flex-center">
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 48,
                    height: 48
                  }}
                />
                <div>
                  {navTab.map((item, index) => {
                    return (
                      <Tag
                        key={item.key}
                        closable
                        onClose={() => {
                          tabDel(index)
                        }}
                        onClick={() => {
                          onMenuClick({ key: item.key })
                        }}
                        color={index === navTabIndex ? '#07c160' : ''}
                      >
                        {item.label}
                      </Tag>
                    )
                  })}
                </div>
              </div>
              <div className="userinfo-right">
                <div className="iconfont icon-bell" />
                <img src={userinfo.avatar} className="logo" />
                <div className="username">{decodeURIComponent(userinfo.userName)}</div>
              </div>
            </Header>
            <div className="flex-1">
              <div className="h-full main-box">
                <Content className="h-full">
                  <Outlet />
                </Content>
              </div>
            </div>
          </Layout>
        </div>
      </Layout>
    </ConfigProvider>
  )
}

export default ManageCenter
