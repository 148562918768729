import React from 'react'
import { Card, DatePicker, Table, Space, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDayMonitorListAPI } from '../../../apis/house'
import Dayjs from 'dayjs'
import './index.scss'
const { Column } = Table

export default function Monitor() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  const [tabsIndex, setTabsIndex] = useState(0)
  const [tabsList, setTabsList] = useState([])
  const [month, setMonth] = useState(Dayjs().format('YYYY-MM')) //  当前月
  const [listQuery, setListQuery] = useState({
    date: undefined
  })
  const [list, setList] = useState([])

  //  获取统计数据
  const fetchData = async () => {
    setListLoading(true)
    const res = await getDayMonitorListAPI(listQuery)
    if (res.success === true) {
      if (res.result) {
        if (tabsList.length == 0) {
          let month = Dayjs(res.result.date).format('YYYY-MM')
          let days = new Date(res.result.date).getDate()
          let tabList = []
          for (let i = 0; i < days; i++) {
            tabList.push({
              key: i,
              label: days - i + '日',
              date: month + '-' + (days - i)
            })
          }
          setTabsList(tabList)
          setMonth(month)
        }
        if (res.result.list) {
          res.result.list.forEach(item => {
            item.newEvidenceTime = Dayjs(item.newEvidenceTime).format('YYYY-MM-DD')
            item.avgPrice = Math.round(item.avgPrice)
          })
          setList(res.result.list)
        } else {
          setList([])
        }
      }
      setListLoading(false)
    }
  }
  const onChange = (date, dateString) => {
    console.log('%c [ dateString ]-39', 'font-size:13px; background:pink; color:#bf2c9f;', dateString)
    setMonth(dateString)
    let days = Dayjs(dateString).daysInMonth()
    console.log('%c [ days ]-41', 'font-size:13px; background:pink; color:#bf2c9f;', days)
    let tabList = []
    for (let i = 0; i < days; i++) {
      tabList.push({
        key: i,
        label: days - i + '日',
        date: dateString + '-' + (days - i)
      })
    }
    setTabsList(tabList)
    listQuery.date = tabList[0].date
    setListQuery({
      ...listQuery,
      date: tabList[0].date
    })
    setList([])
    setTabsIndex(0)
    fetchData()
  }
  //  tabs切换
  const changeTabs = activeKey => {
    setTabsIndex(activeKey)
    listQuery.date = tabsList[activeKey].date
    setListQuery({
      ...listQuery,
      date: tabsList[activeKey].date
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      <div className="pageTitle">
        <div className="pageTitle-name">每日实时网签</div>
      </div>
      <div className="monitor-tabs">
        <div className="monitor-tab">
          <Tabs defaultActiveKey="0" activeKey={tabsIndex} items={tabsList} onChange={changeTabs} />
        </div>

        <DatePicker value={Dayjs(month)} onChange={onChange} picker="month" style={{ width: '200px' }} />
      </div>
      <DataPage list={list} listLoading={listLoading} />
    </div>
  )
}

function DataPage(props) {
  let list = props.list
  return (
    <Card className="card">
      <Table dataSource={list} loading={props.listLoading} pagination={false} sticky>
        <Column title="楼盘名" dataIndex="name" width={250} />
        <Column title="区域" dataIndex="districtName" />
        <Column title="板块" dataIndex="plateName" />
        <Column title="参考价" dataIndex="price" />
        <Column title="取证均价" dataIndex="avgPrice" />
        <Column title="最新取证" dataIndex="newEvidenceTime" />
        <Column title="武房指数" dataIndex="exponent" />
        <Column title="实时网签" dataIndex="soldNum" />
        <Column title="总网签" dataIndex="soldTotal" />
        <Column
          title="操作"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Link to={'/managecenter/house/signmonitor/' + record.id + '?tab=1'}>网签明细</Link>
            </Space>
          )}
        />
      </Table>
    </Card>
  )
}
