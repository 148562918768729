import React from 'react'
import { Card, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { getDevelopersDetailAPI } from '../../../apis/house'
import './index.scss'
const { Column } = Table

export default function DevelopersDetail() {
  const location = useParams()
  const id = location.id

  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  const [detailInfo, setDetailInfo] = useState({}) //  详情
  const [houseList, setHouseList] = useState([]) //  楼盘列表
  const [projectList, setProjectList] = useState([]) //  地块列表

  //  获取楼盘列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getDevelopersDetailAPI({ id })
    if (res.success === true) {
      if (res.result) {
        setDetailInfo(res.result)
        if (res.result.projectList) {
          setProjectList(res.result.projectList)
        }
        if (res.result.houseList) {
          setHouseList(res.result.houseList)
        }
      }
      setListLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <div className="pageTitle">
        <div className="pageTitle-name">{detailInfo.name || ''}</div>
        <div className="pageTitle-desc">
          {detailInfo.brandId ? '所属品牌：' + detailInfo.brandName : ''}
          {detailInfo.brandId ? (
            <Link to={'/manageCenter/brandmap/' + detailInfo.brandId} className="link">
              [品牌地图]
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>

      <Card className="card" title="关联楼盘">
        <Table dataSource={houseList} loading={listLoading} pagination={false}>
          <Column title="楼盘名" dataIndex="houseName" />
          <Column title="区域" dataIndex="districtName" />
          <Column title="板块" dataIndex="plateName" />
          <Column title="参考价" dataIndex="price" />
          <Column title="取证均价" dataIndex="avgPrice" />
          <Column title="最新取证" dataIndex="evidenceNewTime" />
          <Column title="武房指数" dataIndex="exponent" />
          <Column title="30天网签" dataIndex="newSoldNum" />
          <Column title="总网签" dataIndex="soldTotal" />
        </Table>
      </Card>
      <Card className="card" title="关联项目地块">
        <Table dataSource={projectList} loading={listLoading} pagination={false}>
          <Column title="项目地块" dataIndex="projectName" />
          <Column title="楼盘" dataIndex="houseName" />
          <Column title="栋数" dataIndex="buildNum" />
          <Column title="总套数" dataIndex="roomTotal" />
          <Column title="住房已售" dataIndex="residenceSoldNum" />
          <Column title="住房可售" dataIndex="residenceRoomNum" />
          <Column title="非住房已售" dataIndex="otherSoldNum" />
          <Column title="非住房可售" dataIndex="otherRoomNum" />
        </Table>
      </Card>
    </div>
  )
}
