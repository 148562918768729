import React from 'react'
import { Card, Flex, Image } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getHousePhotoListAPI } from '../../../../apis/house'

export default function Photo() {
  const [photoList, setPhotoList] = useState([])

  const location = useParams()
  const id = location.id

  //  相册列表
  const getPhotoList = async () => {
    const res = await getHousePhotoListAPI({ houseId: id })
    if (res.success === true) {
      if (res.result) {
        setPhotoList(res.result)
      }
    }
  }
  useEffect(() => {
    getPhotoList()
  }, [])

  return (
    <Card className="card photolist">
      {photoList.map((item, index) => {
        return (
          <div key={index}>
            <div className="typename">{item.typeName}</div>
            <Flex wrap="wrap" gap="middle">
              <Image.PreviewGroup>
                {item.imgList.map((ele, index2) => {
                  return (
                    <Image
                      key={index2}
                      width={160}
                      src={ele + '?x-oss-process=image/resize,w_160,m_lfit'}
                      preview={{
                        src: ele,
                        current: index2,
                        images: item.imgList
                      }}
                    />
                  )
                })}
              </Image.PreviewGroup>
            </Flex>
          </div>
        )
      })}
    </Card>
  )
}
