import React from 'react'
import { Card, Table, Space, Pagination, Image } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getBrandListAPI } from '../../../apis/house'
import './index.scss'
const { Column } = Table

export default function BrandList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数

  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }

  //  获取品牌列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getBrandListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach((item, index) => {
          item.key = index
        })
      }
      setList(res.result.list)
      setTotal(res.result.total)
      setListLoading(false)
    }
  }

  const [list, setList] = useState() //  列表数据
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <div className="pageTitle">品牌</div>
      <Card className="card">
        <Table dataSource={list} loading={listLoading} pagination={false}>
          <Column title="品牌logo" dataIndex="logoUrl" render={(_, record) => <Image src={record.logoUrl} style={{ maxWidth: '200px', maxHeight: '50px' }} />} />
          <Column title="品牌" dataIndex="name" />
          <Column title="关联楼盘" dataIndex="houseNum" />
          <Column title="关联项目" dataIndex="projectNum" />
          <Column title="关联开发商" dataIndex="developersNum" />
          <Column
            title="操作"
            key="action"
            render={(_, record) => (
              <Space size="middle">
                <Link to={'/managecenter/brandmap/' + record.id}>品牌地图</Link>
              </Space>
            )}
          />
        </Table>
        <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
      </Card>
    </div>
  )
}
