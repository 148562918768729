import React from 'react'
import { Card, Table, Pagination, Space } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseProjectListAPI } from '../../../../apis/house'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
const { Column } = Table

export default function ProjectList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态

  const location = useParams()
  const id = location.id

  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    houseId: id,
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [list, setList] = useState()

  //  获取楼盘户型列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getHouseProjectListAPI(listQuery)
    if (res.success === true) {
      //   if (res.result.list) {
      //     res.result.list.forEach(item => {})
      //   }
      setList(res.result.list)
      setTotal(res.result.total)
      setListLoading(false)
    }
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Card className="card">
      <Table dataSource={list} loading={listLoading} pagination={false}>
        <Column title="项目地块" dataIndex="name" />
        <Column title="楼盘" dataIndex="houseName" />
        <Column title="栋数" dataIndex="bulidNum" />
        <Column title="总套数" dataIndex="roomTotal" />
        <Column title="住房已售" dataIndex="residenceSoldNum" />
        <Column title="住房可售" dataIndex="residenceRoomNum" />
        <Column title="非住房已售" dataIndex="otherSoldNum" />
        <Column title="非住房可售" dataIndex="otherRoomNum" />
        <Column
          title="操作"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Link to={'/managecenter/house/detail/' + record.id}>详情</Link>
              <a>历史记录</a>
            </Space>
          )}
        />
      </Table>
      <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
    </Card>
  )
}
