import React from 'react'
import { Card, Table } from 'antd'
import { useEffect, useState } from 'react'
import { getSignLogListAPI } from '../../../../apis/house'
import * as echarts from 'echarts'
const { Column } = Table

const tabList = ['成交量（套）', '成交面积（万方）']
var myChart

export default function SignLog() {
  const [tabIndex, setTabIndex] = useState(0)
  const [listLoading, setListLoading] = useState(false)
  const [list, setList] = useState([])
  const [titleList, setTitleList] = useState([])
  const [soldChartData, setSoldChartData] = useState({})
  const [areaChartData, setAreaChartData] = useState({})
  const [soldTableList, setSoldTableList] = useState([])
  const [areaTableList, setAreaTableList] = useState([])

  //  获取统计数据
  const fetchData = async () => {
    setListLoading(true)
    const res = await getSignLogListAPI()
    if (res.success === true) {
      let soldChartList = []
      let areaChartList = []
      let soldTableList = []
      let areaTableList = []
      let titleList = []
      if (res.result) {
        res.result.forEach(item => {
          titleList.push(item.year + '年')
          let soldTableItem = {
            year: item.year + '年',
            total: item.soldTotal
          }
          let areaTableItem = {
            year: item.year + '年',
            total: Math.round((item.soldAreaTotal / 10000) * 100) / 100
          }

          let soldDataList = []
          let areaDataList = []
          if (item.dataList) {
            item.dataList.forEach(ele => {
              ele.soldArea = Math.round((ele.soldArea / 10000) * 100) / 100
              soldDataList.push(ele.soldNum)
              areaDataList.push(ele.soldArea)

              if (ele.month == 1) {
                soldTableItem.firstMonth = ele.soldNum
                areaTableItem.firstMonth = ele.soldArea
              } else if (ele.month == 2) {
                soldTableItem.secondMonth = ele.soldNum
                areaTableItem.secondMonth = ele.soldArea
              } else if (ele.month == 3) {
                soldTableItem.thirdMonth = ele.soldNum
                areaTableItem.thirdMonth = ele.soldArea
              } else if (ele.month == 4) {
                soldTableItem.fourthMonth = ele.soldNum
                areaTableItem.fourthMonth = ele.soldArea
              } else if (ele.month == 5) {
                soldTableItem.fifthMonth = ele.soldNum
                areaTableItem.fifthMonth = ele.soldArea
              } else if (ele.month == 6) {
                soldTableItem.sixthMonth = ele.soldNum
                areaTableItem.sixthMonth = ele.soldArea
              } else if (ele.month == 7) {
                soldTableItem.seventhMonth = ele.soldNum
                areaTableItem.seventhMonth = ele.soldArea
              } else if (ele.month == 8) {
                soldTableItem.eighthMonth = ele.soldNum
                areaTableItem.eighthMonth = ele.soldArea
              } else if (ele.month == 9) {
                soldTableItem.ninthMonth = ele.soldNum
                areaTableItem.ninthMonth = ele.soldArea
              } else if (ele.month == 10) {
                soldTableItem.tenthMonth = ele.soldNum
                areaTableItem.tenthMonth = ele.soldArea
              } else if (ele.month == 11) {
                soldTableItem.eleventhMonth = ele.soldNum
                areaTableItem.eleventhMonth = ele.soldArea
              } else if (ele.month == 12) {
                soldTableItem.twelfthMonth = ele.soldNum
                areaTableItem.twelfthMonth = ele.soldArea
              }
            })
          }
          soldChartList.push({
            name: item.year + '年',
            type: 'line',
            data: soldDataList
          })
          areaChartList.push({
            name: item.year + '年',
            type: 'line',
            data: areaDataList
          })
          soldTableList.push(soldTableItem)
          areaTableList.push(areaTableItem)
        })
        setTitleList(titleList)
        setSoldChartData(soldChartList)
        setAreaChartData(areaChartList)
        setSoldTableList(soldTableList)
        setAreaTableList(areaTableList)
        setList(soldTableList)
        setListLoading(false)

        initChart(0, titleList, soldChartList)
      }
    }
  }

  //  tab切换
  const changeTabs = index => {
    if (index == tabIndex) {
      return false
    }
    setListLoading(true)
    setTabIndex(index)
    let chartData = []
    if (index == 1) {
      chartData = areaChartData
      setList(areaTableList)
    } else {
      chartData = soldChartData
      setList(soldTableList)
    }
    setListLoading(false)
    initChart(index, titleList, chartData)
  }

  const initChart = (index, titleList, chartData) => {
    console.log('%c [ titleList ]-136', 'font-size:13px; background:pink; color:#bf2c9f;', titleList)
    console.log('%c [ chartData ]-136', 'font-size:13px; background:pink; color:#bf2c9f;', chartData)
    var option
    option = {
      color: ['#07C160', '#3BA0FF', '#36CBCB', '#FAD337', '#F2637B', '#975FE4'],
      tooltip: {
        trigger: 'axis',
        valueFormatter: value => {
          if (index == 1) {
            return value + '万方'
          } else {
            return value + '套'
          }
        }
      },
      legend: {
        data: titleList
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
      },
      yAxis: {
        type: 'value'
      },
      series: chartData
    }

    option && myChart.setOption(option)
  }

  useEffect(() => {
    var chartDom = document.getElementById('echarts-line')
    myChart = echarts.init(chartDom)
    fetchData()
  }, [])

  return (
    <Card className="card">
      <div className="card-title">
        <div className="card-txt">全市供销存（住宅）</div>
        <div className="housedetail-signtabs">
          {tabList.map((item, index) => {
            return (
              <div className={index == tabIndex ? 'housedetail-signtabs-item housedetail-signtabs-item-active' : 'housedetail-signtabs-item'} key={index} onClick={() => changeTabs(index)}>
                {item}
              </div>
            )
          })}
        </div>
      </div>
      <div id="echarts-line" style={{ width: '100%', height: '350px', margin: '32px 0' }} />
      <Table dataSource={list} loading={listLoading} pagination={false} rowKey="year">
        <Column title="年份" dataIndex="year" />
        <Column title="1月" dataIndex="firstMonth" />
        <Column title="2月" dataIndex="secondMonth" />
        <Column title="3月" dataIndex="thirdMonth" />
        <Column title="4月" dataIndex="fourthMonth" />
        <Column title="5月" dataIndex="fifthMonth" />
        <Column title="6月" dataIndex="sixthMonth" />
        <Column title="7月" dataIndex="seventhMonth" />
        <Column title="8月" dataIndex="eighthMonth" />
        <Column title="9月" dataIndex="ninthMonth" />
        <Column title="10月" dataIndex="tenthMonth" />
        <Column title="11月" dataIndex="eleventhMonth" />
        <Column title="12月" dataIndex="twelfthMonth" />
        <Column title="年度" dataIndex="total" />
      </Table>
    </Card>
  )
}
