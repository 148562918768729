import React from 'react'
import { Card, Input, Cascader, Button, Table, Checkbox, Space, Pagination, Form, DatePicker, message } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getHouseListAPI } from '../../../apis/house'
import { getDistrictListAPI } from '../../../apis/common'
import { followHouse } from '../../../apis/user'
import styles from './index.module.scss'
import Dayjs from 'dayjs'
const { Column } = Table
const { RangePicker } = DatePicker
export default function HouseList() {
  //const [messageApi] = message.useMessage()
  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    keywords: '',
    districtId: 0,
    plateIds: [],
    evidenceStartTime: '',
    evidenceEndTime: '',
    priceMin: '',
    priceMax: '',
    followStatus: 0,
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [districtList, setDistrictList] = useState([]) // 区域板块列表

  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }
  //  我的关注按钮切换
  const changeFollowStatus = e => {
    console.log('%c [ e ]-68', 'font-size:13px; background:pink; color:#bf2c9f;', e)
    listQuery.followStatus = e.target.checked ? 1 : 0
    setListQuery({
      ...listQuery,
      followStatus: e.target.checked ? 1 : 0
    })
    fetchData()
  }
  //  搜索
  const searchFinish = values => {
    console.log('%c [ values ]-78', 'font-size:13px; background:pink; color:#bf2c9f;', values)
    listQuery.keywords = values.keywords
    let districtId = 0
    let plateIds = []
    if (values.plateIds) {
      values.plateIds.forEach(item => {
        if (item.length > 1) {
          districtId = item[0]
          plateIds.push(item[1])
        } else {
          districtId = item[0]
        }
      })
    }
    listQuery.districtId = districtId
    listQuery.plateIds = plateIds
    let evidenceStartTime = ''
    let evidenceEndTime = ''
    if (values.endtime) {
      evidenceStartTime = Dayjs(values.endtime[0]).format('YYYY-MM-DD')
      evidenceEndTime = Dayjs(values.endtime[1]).format('YYYY-MM-DD')
    }
    listQuery.evidenceStartTime = evidenceStartTime
    listQuery.evidenceEndTime = evidenceEndTime
    let priceMax = Number(values.priceMax)
    let priceMin = Number(values.priceMin)
    if (priceMax < priceMin) {
      priceMax = Number(values.priceMin)
      priceMin = Number(values.priceMax)
    }
    listQuery.priceMin = priceMin
    listQuery.priceMax = priceMax
    listQuery.pageInt = 1
    setListQuery({
      ...listQuery
    })
    fetchData()
  }

  //  获取楼盘列表
  const fetchData = async () => {
    setListLoading(true)
    listQuery.priceMin = Number(listQuery.priceMin)
    listQuery.priceMax = Number(listQuery.priceMax)
    const res = await getHouseListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach((item, index) => {
          item.key = index
          item.avgPrice = Math.round(item.avgPrice)
        })
      }
      setList(res.result.list)
      setTotal(res.result.total)
      setListLoading(false)
    }
  }
  //  分类
  const dealData = (array, parentId = 0) => {
    let list = []
    array.forEach(item => {
      if (parentId == item.parentId) {
        let data = {}
        data.label = item.name
        data.value = item.id
        if (item.parentId === 0) {
          data.children = dealData(array, item.id)
        }
        list.push(data)
      }
    })
    if (list.length > 1) {
      list.unshift({ label: '不限', value: 0 })
    }
    return list
  }

  //  获取区域板块列表
  const getDistrictList = async () => {
    const res = await getDistrictListAPI()
    if (res.success === true) {
      if (res.result) {
        let districtList = dealData(res.result, 0)
        setDistrictList(districtList)
      }
    }
  }

  //  关注楼盘
  const changeFollowHouse = async (id, followStatus) => {
    let params = {
      houseId: id,
      followStatus: followStatus ? 0 : 1
    }
    const res = await followHouse(params)
    if (res.success === true) {
      message.success('操作成功')
      fetchData()
    }
  }

  //  获取文章列表
  const [list, setList] = useState()
  useEffect(() => {
    fetchData()
    getDistrictList()
  }, [])

  return (
    <div>
      <div className="pageTitle">楼盘列表</div>
      <Card className="card">
        <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm">
          <Form.Item name="keywords">
            <Input allowClear placeholder="楼盘名关键词" style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item name="plateIds">
            <Cascader allowClear multiple placeholder="区域-板块" options={districtList} style={{ width: '200px', marginLeft: '10px' }} />
          </Form.Item>
          <Form.Item name="endtime">
            <RangePicker allowClear needConfirm picker="date" placeholder={['最新取证开始时间', '最新取证截止时间']} style={{ width: '300px', marginLeft: '10px' }} />
          </Form.Item>
          <Space.Compact size="middle" style={{ width: '300px', marginLeft: '10px' }}>
            <Form.Item name="priceMin">
              <Input addonAfter="元" placeholder="起始参考价" />
            </Form.Item>
            <Form.Item name="priceMax">
              <Input addonAfter="元" placeholder="截止参考价" />
            </Form.Item>
          </Space.Compact>
          <Form.Item>
            <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
              搜索
            </Button>
          </Form.Item>
        </Form>
        <div className={styles['countCondition']}>
          <Checkbox checked={listQuery.followStatus == 1 ? true : false} onChange={changeFollowStatus}>
            我的关注
          </Checkbox>
          <div className={styles['download']}>
            <div className="iconfont icon-download" />
            <div>导出</div>
          </div>
        </div>
        <Table dataSource={list} loading={listLoading} pagination={false}>
          <Column title="楼盘名" dataIndex="name" />
          <Column title="区域" dataIndex="districtName" />
          <Column title="板块" dataIndex="plateName" />
          <Column title="参考价" dataIndex="price" />
          <Column title="取证均价" dataIndex="avgPrice" />
          <Column title="最新取证" dataIndex="evidenceNewTime" />
          <Column title="武房指数" dataIndex="exponent" />
          <Column title="30天网签" dataIndex="newSoldNum" />
          <Column title="总网签" dataIndex="soldTotal" />
          <Column
            title="操作"
            key="action"
            render={(_, record) => (
              <Space size="middle">
                <Link to={'/managecenter/house/detail/' + record.id}>详情</Link>
                {record.followStatus == 1 ? (
                  <Button type="text" onClick={() => changeFollowHouse(record.id, record.followStatus)}>
                    已关注
                  </Button>
                ) : (
                  <Button type="link" onClick={() => changeFollowHouse(record.id, record.followStatus)}>
                    关注
                  </Button>
                )}
              </Space>
            )}
          />
        </Table>
        <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
      </Card>
    </div>
  )
}
