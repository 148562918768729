import React from 'react'
import { Card, Table, Pagination } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseBuildingListAPI } from '../../../../apis/house'
import { useParams } from 'react-router-dom'
const { Column } = Table

export default function EvidenceList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态

  const location = useParams()
  const id = location.id

  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    houseId: id,
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [list, setList] = useState()

  //  获取楼盘户型列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getHouseBuildingListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach(item => {
          item.soldRate = Math.round((item.soldNum / item.roomTotal) * 100, 2) + '%'
        })
      }
      setList(res.result.list)
      setTotal(res.result.total)
      setListLoading(false)
    }
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Card className="card">
      <Table dataSource={list} loading={listLoading} pagination={false}>
        <Column title="项目地块" dataIndex="projectName" />
        <Column
          title="楼栋"
          dataIndex="img"
          key="action"
          render={(_, record) => (
            <div>
              <div>{record.shortName}</div>
              <div className="gray">{record.name}</div>
            </div>
          )}
        />
        <Column title="总楼层" dataIndex="floorTotal" />
        <Column title="总套数" dataIndex="roomTotal" />
        <Column title="住宅" dataIndex="residenceRoomNum" />
        <Column title="已售" dataIndex="soldNum" />
        <Column title="去化率" dataIndex="soldRate" />
        <Column title="未售" dataIndex="roomNum" />
      </Table>
      <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
    </Card>
  )
}
