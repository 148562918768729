import React from 'react'
import { Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getDistrictListAPI } from '../../../apis/common'
import DistrictInfo from './components/districtInfo'
import PlateInfo from './components/plateInfo'
import './index.scss'

export default function District() {
  const [searchParams] = useSearchParams()
  const districtId = Number(searchParams.get('districtid')) || 0
  console.log('districtId', districtId)
  const plateId = Number(searchParams.get('plateid')) || 0
  console.log('plateId', plateId)
  //const query = Object.fromEntries(searchParams);

  const navigate = useNavigate()
  const [tabsIndex, setTabsIndex] = useState(0)
  const [tabsList, setTabsList] = useState([
    {
      key: 0,
      id: 0,
      label: '概况',
      children: <DistrictInfo />
    }
  ])

  //  获取区域板块列表
  const getDistrictList = async () => {
    const res = await getDistrictListAPI()
    if (res.success === true) {
      if (res.result) {
        let tabsList = [
          {
            key: 0,
            id: 0,
            label: '概况',
            children: <DistrictInfo />
          }
        ]
        res.result.forEach((item, index) => {
          if (item.parentId === 0) {
            if (item.id === districtId) {
              tabsList.push({
                key: index + 1,
                id: item.id,
                label: item.name,
                children: <PlateInfo districtId={item.id} plateId={plateId} />
              })
              setTabsIndex(index + 1)
            } else {
              tabsList.push({
                key: index + 1,
                id: item.id,
                label: item.name,
                children: <PlateInfo districtId={item.id} />
              })
            }
          }
        })
        setTabsList(tabsList)
      }
    }
  }
  //  tab切换
  const changeTabs = key => {
    let path = '/managecenter/supplymarket/district?districtid=' + tabsList[key].id
    navigate(path)
    setTabsIndex(key)
  }
  useEffect(() => {
    getDistrictList()
  }, [districtId])
  return (
    <div>
      <div className="pageTitle">
        <div className="pageTitle-name">区域供销存</div>
      </div>
      <div className="tabs">
        <Tabs activeKey={tabsIndex} items={tabsList} onTabClick={changeTabs} />
      </div>
    </div>
  )
}
