import React from 'react'
import { Card, Table, Pagination, Space, Row, Col, Form, Input, Button } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseProjectListAPI, getProjectCountDataAPI } from '../../../apis/house'
import { Link } from 'react-router-dom'
const { Column } = Table

export default function ProjectList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态

  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    pageInt: 1,
    pageSize: 10,
    houseName: '',
    projectName: ''
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [list, setList] = useState()
  const [countData, setCountData] = useState({})

  //  获取楼盘户型列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getHouseProjectListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach((item, index) => {
          item.key = index + 1
        })
      }
      setList(res.result.list)
      setTotal(res.result.total)
    } else {
      setList([])
      setTotal(0)
    }
    setListLoading(false)
  }

  //  获取楼盘户型列表
  const getProjectCountData = async () => {
    setListLoading(true)
    const res = await getProjectCountDataAPI({ houseName: listQuery.houseName, projectName: listQuery.projectName })
    if (res.success === true) {
      setCountData(res.result)
    }
  }
  // 搜索
  const searchFinish = values => {
    listQuery.houseName = values.houseName
    listQuery.projectName = values.projectName
    listQuery.pageInt = 1
    setListQuery({
      ...listQuery
    })
    fetchData()
    getProjectCountData()
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
    getProjectCountData()
  }, [])
  return (
    <Card className="card">
      <Row gutter={[22, 22]}>
        <Col span="4">
          <div className="projectItem">
            <div className="projectItem-txt">项目数</div>
            <div className="projectItem-num">{countData.total || 0}</div>
          </div>
        </Col>
        <Col span="4">
          <div className="projectItem">
            <div className="projectItem-txt">房源总套数</div>
            <div className="projectItem-num">{countData.roomTotal || 0}</div>
          </div>
        </Col>
        <Col span="4">
          <div className="projectItem">
            <div className="projectItem-txt">住宅已售</div>
            <div className="projectItem-num">{countData.residenceSoldNum || 0}</div>
          </div>
        </Col>
        <Col span="4">
          <div className="projectItem">
            <div className="projectItem-txt">住宅可售</div>
            <div className="projectItem-num green">{countData.residenceRoomNum || 0}</div>
          </div>
        </Col>
        <Col span="4">
          <div className="projectItem">
            <div className="projectItem-txt">非住宅已售</div>
            <div className="projectItem-num">{countData.otherSoldNum || 0}</div>
          </div>
        </Col>
        <Col span="4">
          <div className="projectItem">
            <div className="projectItem-txt">非住宅可售</div>
            <div className="projectItem-num">{countData.otherRoomNum || 0}</div>
          </div>
        </Col>
      </Row>
      <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm" style={{ marginTop: '24px' }}>
        <Form.Item name="houseName">
          <Input allowClear placeholder="楼盘名称" style={{ width: '200px', marginLeft: '10px' }} />
        </Form.Item>
        <Form.Item name="projectName">
          <Input allowClear placeholder="项目名称" style={{ width: '200px', marginLeft: '10px' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
            搜索
          </Button>
        </Form.Item>
      </Form>
      <Table dataSource={list} loading={listLoading} pagination={false}>
        <Column title="项目地块" dataIndex="name" />
        <Column title="楼盘" dataIndex="houseName" />
        <Column title="栋数" dataIndex="buildingNum" />
        <Column title="总套数" dataIndex="roomTotal" />
        <Column title="住房已售" dataIndex="residenceSoldNum" />
        <Column title="住房可售" dataIndex="residenceRoomNum" />
        <Column title="非住房已售" dataIndex="otherSoldNum" />
        <Column title="非住房可售" dataIndex="otherRoomNum" />
        <Column
          title="操作"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Link to={'/managecenter/house/detail/' + record.id}>详情</Link>
              <a>历史记录</a>
            </Space>
          )}
        />
      </Table>
      <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
    </Card>
  )
}
