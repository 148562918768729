//  封装和token相关的方法 存 取 删

const TOKEN = 'token'
//  存token
function setToken(token) {
  localStorage.setItem(TOKEN, token)
}

//  取token
function getToken() {
  return localStorage.getItem(TOKEN)
}

//  删token
function removeToken() {
  localStorage.removeItem(TOKEN)
}

const USERINFO = 'userinfo'
//  存userinfo
function setUserInfo(userinfo) {
  localStorage.setItem(USERINFO, JSON.stringify(userinfo))
}

//  取userinfo
function getUserInfo() {
  const userInfo = localStorage.getItem(USERINFO)
  return JSON.parse(userInfo)
}

//  删userinfo
function removeUserInfo() {
  localStorage.removeItem(USERINFO)
}

export { setToken, getToken, removeToken, setUserInfo, getUserInfo, removeUserInfo }
