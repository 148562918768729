import React from 'react'
import { Card, Col, Row, Form, Cascader, Table, Select, Button } from 'antd'
import { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
import { getExponentRankListAPI } from '../../../../apis/house'
import { getDistrictListAPI } from '../../../../apis/common'
import Dayjs from 'dayjs'
const { Column } = Table

export default function ExponentRank() {
  const [districtList, setDistrictList] = useState([]) // 区域板块列表
  //    榜单分类
  let today = Dayjs().format('YYYY-MM-DD')
  let todayTime = Dayjs(today).unix()
  let typeList = []
  //  近期
  typeList.push({
    value: 0,
    label: '近期',
    timeList: [
      {
        value: 0,
        label: '近90天',
        startTime: Dayjs((todayTime - 90 * 86400) * 1000).format('YYYY-MM-DD'),
        endTime: today
      },
      {
        value: 1,
        label: '近30天',
        startTime: Dayjs((todayTime - 30 * 86400) * 1000).format('YYYY-MM-DD'),
        endTime: today
      }
    ]
  })
  //  季榜
  let quarterTimeList = []
  let curQuarter = Math.ceil((Dayjs().month() + 1) / 3)
  let quarterYear = Dayjs().year()
  if (curQuarter == 1) {
    quarterYear--
    curQuarter = 4
  } else {
    curQuarter--
  }
  for (let i = 0; i < 12; i++) {
    let year = quarterYear
    let quarter = curQuarter
    if (curQuarter > i) {
      quarter = curQuarter - i
    } else {
      let difference = Math.floor((i - curQuarter) / 4) + 1
      year = quarterYear - difference
      quarter = curQuarter - i + 4 * difference
    }
    if (year < 2023) {
      break
    }
    quarterTimeList.push({
      value: i,
      label: year + '年' + quarter + '季度',
      startTime: year + '-' + ((quarter - 1) * 3 + 1) + '-01',
      endTime: year + '-' + quarter * 3 + '-' + new Date(year, quarter * 3, 0).getDate(),
      year: year
    })
  }
  typeList.push({
    value: 1,
    label: '季榜',
    timeList: quarterTimeList
  })

  //  年榜
  let yearTimeList = []
  let yearYear = Dayjs().year() - 1
  for (let i = 0; i < 4; i++) {
    let year = yearYear - i
    if (year < 2023) {
      continue
    }
    yearTimeList.push({
      value: i,
      label: year + '年',
      startTime: year + '-01-01',
      endTime: year == new Date().getFullYear() ? year + '-12-31' : year + 1 + '-01-01',
      year: year
    })
  }
  typeList.push({
    value: 2,
    label: '年榜',
    timeList: yearTimeList
  })
  const [typeIndex, setTypeIndex] = useState(0)
  const [timeIndex, setTimeIndex] = useState(0)

  //  数量
  let pageSizeList = [
    {
      value: 10,
      label: 'TOP10'
    },
    {
      value: 20,
      label: 'TOP20'
    },
    {
      value: 30,
      label: 'TOP30'
    },
    {
      value: 40,
      label: 'TOP40'
    },
    {
      value: 50,
      label: 'TOP50'
    }
  ]

  const [listLoading, setListLoading] = useState(false) //  表格加载状态
  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    districtId: 0, //	区域编号
    plateId: 0, //	板块编号
    startTime: typeList[typeIndex].timeList[timeIndex].startTime, //	开始日期
    endTime: typeList[typeIndex].timeList[timeIndex].endTime, //	截止日期
    pageInt: 1,
    pageSize: 50
  })
  const [list, setList] = useState([])
  const [districtName, setDistrictName] = useState('武汉市')

  //  获取区域板块列表
  const getDistrictList = async () => {
    const res = await getDistrictListAPI()
    if (res.success === true) {
      if (res.result) {
        let districtList = dealData(res.result, 0)
        districtList[0].label = '武汉市'
        setDistrictList(districtList)
      }
    }
  }
  //  分类
  const dealData = (array, parentId = 0) => {
    let list = []
    array.forEach(item => {
      if (parentId == item.parentId) {
        let data = {}
        data.label = item.name
        data.value = item.id
        if (item.parentId === 0) {
          data.children = dealData(array, item.id)
        }
        list.push(data)
      }
    })
    if (list.length > 1) {
      list.unshift({ label: '不限', value: 0 })
    }
    return list
  }

  //  获取楼盘列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getExponentRankListAPI(listQuery)
    if (res.success === true) {
      if (res.result && res.result.data) {
        res.result.data.forEach(item => {
          item.key = item.id
        })
        setList(res.result.data)
      }
      setListLoading(false)
    }
  }
  //  类型变更
  const changeType = value => {
    setTypeIndex(value)
    setTimeIndex(0)
  }
  //  时间段变更
  const changeTime = value => {
    setTimeIndex(value)
  }
  //  搜索
  const searchFinish = values => {
    console.log('%c [ values ]-78', 'font-size:13px; background:pink; color:#bf2c9f;', values)
    listQuery.pageSize = values.pageSize
    if (values.plateIds) {
      listQuery.districtId = values.plateIds[0]
      listQuery.plateId = values.plateIds[1]

      let district = ''
      districtList.forEach(item => {
        if (item.value == listQuery.districtId) {
          district = item.label
          if (listQuery.plateId) {
            item.children.forEach(ele => {
              if (ele.value == listQuery.plateId) {
                district = district + '-' + ele.label
              }
            })
          }
        }
      })
      setDistrictName(district)
    }
    listQuery.startTime = typeList[typeIndex].timeList[timeIndex].startTime
    listQuery.endTime = typeList[typeIndex].timeList[timeIndex].endTime
    listQuery.pageInt = 1
    setListQuery({
      ...listQuery
    })
    fetchData()
  }

  useEffect(() => {
    getDistrictList()
    fetchData()
  }, [])
  return (
    <div>
      <Row gutter={[-24]}>
        <Col span="16">
          <Card className="card">
            <div style={{ fontSize: '14px', color: '#999999', lineHeight: '22px', marginBottom: '12px' }}>由AI数据楼盘网签量、用户关注度等综合打分获得，仅供参考</div>
            <Form initialValues={listQuery} onFinish={searchFinish} className="searchForm">
              <Form.Item name="plateIds">
                <Cascader allowClear placeholder="武汉市" options={districtList} style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item>
                <Select value={typeIndex} options={typeList} onChange={changeType} style={{ width: '150px', marginLeft: '10px' }} />
              </Form.Item>
              <Form.Item>
                <Select value={timeIndex} options={typeList[typeIndex].timeList} onChange={changeTime} style={{ width: '150px', marginLeft: '10px' }} />
              </Form.Item>
              <Form.Item name="pageSize">
                <Select options={pageSizeList} style={{ width: '150px', marginLeft: '10px' }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="searchBtn" htmlType="submit" style={{ marginLeft: '10px' }}>
                  生成榜单
                </Button>
              </Form.Item>
            </Form>
            <Table dataSource={list} loading={listLoading} pagination={false}>
              <Column title="排名" dataIndex="rank" />
              <Column title="楼盘" dataIndex="name" />
              <Column title="区域" dataIndex="districtName" />
              <Column title="板块" dataIndex="plateName" />
              <Column title="武房指数" dataIndex="exponent" />
            </Table>
          </Card>
        </Col>
        <Col span="8">
          <div className="poster exponent">
            <div className="poster-top">
              <div className="poster-top-tit">武房通榜单</div>
              <div className="poster-top-content">
                <div className="iconfont icon-icon20-maisuiyou" />
                <div className="poster-top-center">
                  <div className="poster-top-title">武房指数榜 TOP{listQuery.pageSize}</div>
                  <div className="poster-top-label">
                    {districtName} {typeList[typeIndex].label} {typeList[typeIndex].timeList[timeIndex].label}
                  </div>
                </div>
                <div className="iconfont icon-icon20-maisuiyou" />
              </div>
            </div>
            <div className="poster-desc">由AI数据楼盘网签量、用户关注度等综合打分获得，仅供参考</div>
            <div className="poster-list">
              {list.map((item, index) => {
                return (
                  <div className="poster-item" key={index}>
                    <div className="poster-item-left">
                      <div className={item.rank > 3 ? 'poster-item-rank' : 'poster-item-rank rank' + item.rank}>{item.rank}</div>
                      <div className="poster-item-name">{item.name}</div>
                    </div>
                    <div>{item.exponent}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
