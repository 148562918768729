import React from 'react'
import { Button, Image, Modal, Tabs, message } from 'antd'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getHouseDetailAPI } from '../../../apis/house'
import { followHouse } from '../../../apis/user'
import HouseInfo from './components/houseInfo'
import SignMonitor from './components/signMonitor'
import RoomList from './components/roomList'
import SoldChartList from './components/soldChartList'
import EvidenceList from './components/evidenceList'
import ProjectList from './components/projectList'
import BuildingList from './components/buildingList'
import RoomTypeList from './components/roomTypeList'
import Photo from './components/photo'

export default function Detail() {
  const [count, setCount] = useState(0)
  const [detailData, setDetailData] = useState({}) //  楼盘详情
  const [wxQrcode, setWxQrcode] = useState(false) // 微信码
  const [douyinQrcode, setDouyinQrcode] = useState(false) // 抖音码
  const [tabsKey, setTabsKey] = useState(0)

  const navigate = useNavigate()
  const location = useParams()
  const id = location.id
  const name = location.name

  //let searchParams = useLocation()
  //searchParams = searchParams.search.replace("?", "");
  //  楼盘详情
  const getDetail = async () => {
    const res = await getHouseDetailAPI({ id })
    if (res.success === true) {
      setDetailData(res.result)
    }
  }
  const changeWxQrcode = () => {
    setWxQrcode(!wxQrcode)
  }
  const changeDouyinQrcode = () => {
    setDouyinQrcode(!douyinQrcode)
  }
  //  关注楼盘
  const changeFollowHouse = async () => {
    let params = {
      houseId: id,
      followStatus: detailData.followStatus ? 0 : 1
    }
    const res = await followHouse(params)
    if (res.success === true) {
      message.success('操作成功')
      setDetailData({
        ...detailData,
        followStatus: params.followStatus
      })
    }
  }
  //  tab切换
  const changeTabs = key => {
    let path = '/managecenter/house/' + tabsList[key].name + '/' + id
    navigate(path)
    setCount(count + 1)
  }

  const toLinkTabs = url => {
    navigate(url)
    setCount(count + 1)
  }

  const tabsList = [
    {
      key: '0',
      label: '概况',
      name: 'detail',
      children: <HouseInfo changeLinkTabs={toLinkTabs} />
    },
    {
      key: '1',
      label: '网签监测',
      name: 'signmonitor',
      children: <SignMonitor />
    },
    {
      key: '2',
      label: '房源分布',
      name: 'roomList',
      children: <RoomList />
    },
    {
      key: '3',
      label: '销控图',
      name: 'soldchartlist',
      children: <SoldChartList />
    },
    {
      key: '4',
      label: '预售证',
      name: 'evidencelist',
      children: <EvidenceList />
    },
    {
      key: '5',
      label: '项目地块',
      name: 'projectlist',
      children: <ProjectList />
    },
    {
      key: '6',
      label: '楼栋',
      name: 'buildinglist',
      children: <BuildingList />
    },
    {
      key: '7',
      label: '户型',
      name: 'roomtypelist',
      children: <RoomTypeList />
    },
    {
      key: '8',
      label: '相册',
      name: 'photo',
      children: <Photo />
    }
  ]

  useEffect(() => {
    let key = '0'
    tabsList.forEach(item => {
      if (item.name === name) {
        key = item.key
      }
    })
    setTabsKey(key)
    getDetail()
  }, [count])
  return (
    <div>
      <div className="pageTitle">
        <div className="pageTitle-name">{detailData.name}</div>
        <Button size="small" className="pageTitle-btn" onClick={changeFollowHouse}>
          {detailData.followStatus ? '已关注' : '关注'}
        </Button>
        <Button size="small" className="pageTitle-btn" onClick={changeWxQrcode}>
          微信码
        </Button>
        <Button size="small" className="pageTitle-btn" onClick={changeDouyinQrcode}>
          抖音码
        </Button>
      </div>
      <div className="tabs">
        <Tabs activeKey={tabsKey} items={tabsList} onTabClick={changeTabs} />
      </div>
      <Modal footer={null} open={wxQrcode} title="微信码" onCancel={changeWxQrcode} style={{ textAlign: 'center' }}>
        <Image src={detailData.weixinQrcode} width="200px" style={{ padding: '40px 0' }} />
      </Modal>
      <Modal footer={null} open={douyinQrcode} title="抖音码" onCancel={changeDouyinQrcode} style={{ textAlign: 'center' }}>
        <Image src={detailData.douyinQrcode} width="200px" style={{ padding: '40px 0' }} />
      </Modal>
    </div>
  )
}
