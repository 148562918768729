//  楼盘相关的所有请求
import { request } from '../utils'

//  楼盘列表
export function getHouseListAPI(data) {
  return request({
    url: '/data/GetLouPanList',
    method: 'POST',
    data
  })
}

//  楼盘概况
export function getHouseDetailAPI(data) {
  return request({
    url: '/data/GetLouPanDetail',
    method: 'POST',
    data
  })
}

//  楼盘价格走势
export function getHousePriceAccountAPI(data) {
  return request({
    url: '/data/GetLouPanPriceTrend',
    method: 'POST',
    data
  })
}

//  楼盘销控图列表
export function getHouseSoldChartListAPI(data) {
  return request({
    url: '/data/GetLouPanSaleImages',
    method: 'POST',
    data
  })
}

//  楼盘网签列表
export function getHouseSignListAPI(data) {
  return request({
    url: '/data/GetLouPanWangQianList',
    method: 'POST',
    data
  })
}

//  楼盘已售房源列表
export function getHouseSoldRoomListAPI(data) {
  return request({
    url: '/data/GetLouPanHouseList',
    method: 'POST',
    data
  })
}

//  楼盘房源列表
export function getHouseRoomListAPI(data) {
  return request({
    url: '/data/GetLouPanRoomItems',
    method: 'POST',
    data
  })
}

//  楼盘预售证列表
export function getHouseEvidenceListAPI(data) {
  return request({
    url: '/data/GetLouPanPreSaleCertificateList',
    method: 'POST',
    data
  })
}

//  楼盘项目地块列表
export function getHouseProjectListAPI(data) {
  return request({
    url: '/data/GetLouPanProjectBlockList',
    method: 'POST',
    data
  })
}

//  楼盘楼栋列表
export function getHouseBuildingListAPI(data) {
  return request({
    url: '/data/GetProjectBuildingSaleDetails',
    method: 'POST',
    data
  })
}

//  楼盘户型列表
export function getHouseRoomTypeListAPI(data) {
  return request({
    url: '/data/GetLouPanRoomType',
    method: 'POST',
    data
  })
}

//  楼盘相册列表
export function getHousePhotoListAPI(data) {
  return request({
    url: '/data/GetLouPanPhotos',
    method: 'POST',
    data
  })
}

//  获取楼盘房源分布数据
export function getRoomCountDataAPI(data) {
  return request({
    url: '/data/GetLouPanRoomDistribution',
    method: 'POST',
    data
  })
}

//  获取项目地块统计数据
export function getProjectCountDataAPI(data) {
  return request({
    url: '/data/GetHouseProjectBlockStatistic',
    method: 'POST',
    data
  })
}

//  供销存全市统计数据
export function getAllCityCountDataAPI(data) {
  return request({
    url: '/data/GetWangQianStatisticData',
    method: 'POST',
    data
  })
}
//  供销存全市每日成交量列表
export function getAllCitySignListAPI(data) {
  return request({
    url: '/data/GetWangQianData',
    method: 'POST',
    data
  })
}
//  供销存全市历年住宅成交列表
export function getSignLogListAPI(data) {
  return request({
    url: '/data/GetSaleStatistic',
    method: 'POST',
    data
  })
}
//  供销存区域板块概况数据
export function getPlateCountListAPI(data) {
  return request({
    url: '/data/RegionalOverviewList',
    method: 'POST',
    data
  })
}
//  供销存区域板块统计数据
export function getPlateCountDataAPI(data) {
  return request({
    url: '/data/GetLouPanDynamicData',
    method: 'POST',
    data
  })
}
//  供销存区域板块活跃楼盘列表
export function getPlateHotHouseListAPI(data) {
  return request({
    url: '/data/GetDynamicLouPanList',
    method: 'POST',
    data
  })
}
//  供销存区域板块库存统计
export function getPlateStockCountAPI(data) {
  return request({
    url: '/data/GetLouPanAreaRoomStockData',
    method: 'POST',
    data
  })
}
//  供销存区域板块供应统计
export function getPlateSupplyCountAPI(data) {
  return request({
    url: '/data/GetLouPanAreaRoomEvidenceData',
    method: 'POST',
    data
  })
}
//  供销存区域板块成交统计
export function getPlateSoldCountAPI(data) {
  return request({
    url: '/data/GetLouPanAreaRoomSaleData',
    method: 'POST',
    data
  })
}
//  供销存-每日实时网签
export function getSoldChartImgListAPI(data) {
  return request({
    url: '/data/GetLouPanSaleImage',
    method: 'POST',
    data
  })
}
//  供销存-销控图集
export function getDayMonitorListAPI(data) {
  return request({
    url: '/data/GetWangQianHouseData',
    method: 'POST',
    data
  })
}

/** 榜单 **/
//  榜单-销量榜
export function getSoldRankListAPI(data) {
  return request({
    url: '/data/GetLouPanSaleStatisticRank',
    method: 'POST',
    data
  })
}
//  榜单-销售额榜
export function getSoldPriceRankListAPI(data) {
  return request({
    url: '/data/GetLouPanSaleBang',
    method: 'POST',
    data
  })
}
//  榜单-指数榜
export function getExponentRankListAPI(data) {
  return request({
    url: '/data/GetLouPanSaleIndexRank',
    method: 'POST',
    data
  })
}

//  竞品雷达搜索
export function searchHouseAPI(data) {
  return request({
    url: '/data/SearchLouPan',
    method: 'POST',
    data
  })
}

//  竞品雷达楼盘树数据
export function getHouseTreeDataAPI(data) {
  return request({
    url: '/data/LouPanRadar',
    method: 'POST',
    data
  })
}

//  竞品雷达楼盘树数据
export function getHouseTreeData2API(data) {
  return request({
    url: '/data/LouPanRadar2',
    method: 'POST',
    data
  })
}

//  库存地图数据
export function getStockMapDataAPI(data) {
  return request({
    url: '/data/GetLouPanAreaStockRank',
    method: 'POST',
    data
  })
}

//  品牌列表
export function getBrandListAPI(data) {
  return request({
    url: '/data/GetBrandList',
    method: 'POST',
    data
  })
}

//  全部品牌列表
export function getAllBrandListAPI(data) {
  return request({
    url: '/data/GetAllBrandList',
    method: 'POST',
    data
  })
}

//  开发商列表
export function getDevelopersListAPI(data) {
  return request({
    url: '/data/GetDeveloperCompanyList',
    method: 'POST',
    data
  })
}

//  开发商详情
export function getDevelopersDetailAPI(data) {
  return request({
    url: '/data/GetDeveloperCompanyDetail',
    method: 'POST',
    data
  })
}

//  开发商详情
export function getBrandHouseListAPI(data) {
  return request({
    url: '/data/GetBrandHouseList',
    method: 'POST',
    data
  })
}
