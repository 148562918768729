import React from 'react'
import { Card, Table, Pagination, Space } from 'antd'
import { useEffect, useState } from 'react'
import { getHouseEvidenceListAPI } from '../../../../apis/house'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Dayjs from 'dayjs'
const { Column } = Table

export default function EvidenceList() {
  const [listLoading, setListLoading] = useState(false) //  表格加载状态

  const location = useParams()
  const id = location.id

  //  列表请求参数
  const [listQuery, setListQuery] = useState({
    houseId: id,
    pageInt: 1,
    pageSize: 10
  })
  const [total, setTotal] = useState(0) //  列表总数
  const [list, setList] = useState()

  //  获取楼盘户型列表
  const fetchData = async () => {
    setListLoading(true)
    const res = await getHouseEvidenceListAPI(listQuery)
    if (res.success === true) {
      if (res.result.list) {
        res.result.list.forEach(item => {
          item.soldRate = Math.round(item.soldRate * 100, 2) + '%'
          item.time = Dayjs(item.time).format('YYYY-MM-DD')
          item.avgPrice = item.avgPrice + '元/平'
        })
      }
      setList(res.result.list)
      setTotal(res.result.total)
      setListLoading(false)
    }
  }
  const onChange = (page, pageSize) => {
    listQuery.pageInt = page
    listQuery.pageSize = pageSize
    setListQuery({
      ...listQuery,
      pageInt: page,
      pageSize: pageSize
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Card className="card">
      <Table dataSource={list} loading={listLoading} pagination={false}>
        <Column title="预售证" dataIndex="name" />
        <Column title="楼盘名称" dataIndex="houseName" />
        <Column title="取证时间" dataIndex="time" />
        <Column title="备案均价" dataIndex="avgPrice" />
        <Column title="房源" dataIndex="roomNum" />
        <Column title="已售" dataIndex="soldNum" className="red" />
        <Column title="去化率" dataIndex="soldRate" className="red" />
        <Column title="未售" dataIndex="availableRoomNum" className="green" />
        <Column title="更新时间" dataIndex="updateTime" />
        <Column
          title="操作"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Link to={'/managecenter/house/detail/' + record.id}>详情</Link>
            </Space>
          )}
        />
      </Table>
      <Pagination hideOnSinglePage defaultPageSize={20} showTotal={total => '共 ' + total + ' 条数据'} pageSize={listQuery.pageSize} pageSizeOptions={['10', '20', '30', '50', '100']} total={total} showSizeChanger showQuickJumper onChange={onChange} />
    </Card>
  )
}
